<ng-template #verEvaluacion role="document" let-modal>
<div class="modal-content" [class.dark-modal]="themeService.isDark()">
    <div class="modal-header">
        <h5 class="modal-title" [class.dark-title]="themeService.isDark()" id="myModalLabel">Evaluacion de: {{nombreCurso}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="cerrarModal()"></button>
    </div>
    <div class="modal-body">
        <section class="why-choose-us-area-two ptb-custom" >
            <div class="container">
                <div class="row align-items-center">
                    <form #form="ngForm" (ngSubmit)="guardarEncuesta(form)">
                        <input id="tipoEncuesta" type="hidden" pInputText value="{{true}}" />

                        <div *ngFor="let preguntaEvaluacion of preguntasEvaluacion; let i=index">
                          <h3>{{preguntaEvaluacion.pregunta}}</h3>
                          <!-- respuestas Encuestas -->
                          <div *ngIf="preguntaEvaluacion.tipoPregunta === 'comentario' ">
                            <textarea (keyup)="ocultarDiv(form, 'comentario', i+1)" rows="5" cols="75"  name=comentario{{i+1}} ngModel type="text" placeholder="Ingresa tu comentario (Mínimo 20 caracteres)" #comentario{{i}}="ngModel" [minlength]="comentarioLength"></textarea> <br />
                            <div id="mensajeErrorCom{{i+1}}" style="display: none;">
                                <div class="alert alert-danger" role="alert">
                                    Tu comentario debe contener mínimo 20 caracteres.
                                </div>
                              <br />
                            </div>
                          </div>

                          <!-- Pregunta de si o no -->
                          <div *ngIf="preguntaEvaluacion.tipoPregunta === 's/n' " class="form-check-inline">
                            <input class="form-check-input" type="radio" (change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="Si" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                Si
                            </label>
                            <br />
                            <input class="form-check-input" type="radio" (change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="No" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                No
                            </label>
                            <br /> <br />
                            <div id="mensajeErrorRes{{i+1}}" class="mensaje-error" *ngIf="!form.controls.touched">
                                <div class="alert alert-danger" role="alert">
                                    Debes seleccionar una opcion
                                </div>
                                <br />
                            </div>
                          </div>

                          <!-- Pregunta de si o no y comentario-->
                          <div *ngIf="preguntaEvaluacion.tipoPregunta === 's/n/c' " class="form-check-inline">
                            <input class="form-check-input" type="radio" (change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="Si" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                Si
                            </label>
                            <br />
                            <input class="form-check-input" type="radio" (change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="No" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                No
                            </label><br /> <br />
                            <div id="mensajeErrorRes{{i+1}}"  class="mensaje-error" *ngIf="!form.controls.touched">
                                <div class="alert alert-danger" role="alert">
                                    Debes seleccionar una opcion
                                </div> <br /> <br />
                            </div>
                            <textarea (keyup)="ocultarDiv(form, 'comentario', i+1)" rows="5" cols="75" [minlength]="comentarioLength" name=comentario{{i+1}} ngModel type="text" placeholder="Ingresa tu comentario (Mínimo 20 caracteres)" #comentario{{i}}="ngModel"></textarea> <br />
                            <div id="mensajeErrorCom{{i+1}}" style="display: none;">
                                <div class="alert alert-danger" role="alert">
                                    Tu comentario debe contener mínimo 20 caracteres.
                                </div> <br />
                            </div>
                          </div>

                          <!-- Pregunta de nivel -->
                          <div *ngIf="preguntaEvaluacion.tipoPregunta === 'nivel' " class="form-check-inline">
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="Malo" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                Malo
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="Regular" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                Regular
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="Bueno" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                Bueno
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="Excelente" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                Excelente
                            </label><br />
                            <div id="mensajeErrorRes{{i+1}}"  class="mensaje-error" *ngIf="!form.controls.touched">
                                <div class="alert alert-danger" role="alert">
                                    Debes seleccionar una opcion
                                </div>
                                <br />
                            </div>
                          </div>


                          <div *ngIf="preguntaEvaluacion.tipoPregunta === 'calificacion' " class="form-check-inline">
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="50" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                50
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="60" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                60
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="70" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                70
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="80" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                80
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="90" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                90
                            </label>
                            <br />
                            <input class="form-check-input" type="radio"(change)="seleccionado(form, 'respuesta', i+1)" name="respuesta{{i+1}}" id="respuesta{{i+1}}" value="100" ngModel #groupname="ngModel" required="true">
                            <label class="form-check-label" for="respuesta{{i+1}}">
                                100
                            </label>
                            <br />
                            <div id="mensajeErrorRes{{i+1}}" class="mensaje-error" *ngIf="!form.controls.touched">
                                <div class="alert alert-danger" role="alert">
                                    Debes seleccionar una opcion
                                </div>
                                <br />
                            </div>
                          </div>

                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="msjErrorForm">
                            Llenar los campos correspondientes
                        </div> <br />
                        <button type="submit" class="btn btn-sm btn-primary order-button-icon" style="margin-right: 10px; margin-left: 10px;">Enviar</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>
</ng-template>
