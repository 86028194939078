import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {
  sort<T>(data: T[], prop: string, order: 'asc' | 'desc'): T[] {
    const sortFn = (a: any, b: any) => {
      let aValue = prop.split('.').reduce((o, i) => o[i], a);
      let bValue = prop.split('.').reduce((o, i) => o[i], b);

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    };
    return data.sort(sortFn);
  }
}
