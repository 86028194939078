<footer>
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">2750 Quadra Street Victoria Road, New York, United States</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello&#64;ednuv.com">hello&#64;ednuv.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <div style="display: inline-flex; align-items: center;">
                    <a routerLink="/" class="d-inline-block"><img src="assets/img/logo64.png" alt="image"></a>
                    <div>
                        <h3 style="color: #cccccc; font-size: 18px;
                            padding-bottom: 0px;
                            margin-bottom: 0px;">TG Alumnos</h3>
                        <p>© TecGurus {{ currentYear }}</p>
                    </div>
                </div>
                
            </div>
            <div class="footer-icons" style="font-size: 34px; display: inline-flex; gap: 5%;">
                <a style="color: #cccccc;" routerLink="/"><i class="bx bx-home"></i></a>
                <a style="color: #cccccc;" href="https://www.tecgurus.net/" target="_blank">
                    <i class="bx bx-world"></i>
                </a>
            </div>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>