import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class BibliotecaService {
  
    url:string;
  
    constructor(private http: HttpClient) {
      this.url = environment.baseApi;
    }
  
    busquedaById(idBiblioteca:any){
      const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
      return this.http.get(this.url+"biblioteca/detalle/"+idBiblioteca,{headers});
    }
  
    todas(){
      const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
      return this.http.get(this.url+"biblioteca/todas/",{headers});
    }

    temarioBiblioteca(idBiblioteca:any){
      const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
      return this.http.get(this.url+"biblioteca/temario/"+idBiblioteca,{headers});
    }

    registrarVistaSubtema(jsonData:any){
      const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
      return this.http.post(this.url+"biblioteca/guardar-vista-subtema",jsonData,{headers});
    }
}