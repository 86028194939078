import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {

  urlApi:string;

  constructor(private http: HttpClient) {
    this.urlApi = environment.baseApi;
  }

  todas(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.urlApi+ "encuesta/encuestas-instructores/",{headers});
  }

    validarEncuestasPendientes(idAlumno:String){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.urlApi+ "encuesta/pendientes/"+idAlumno,{headers});
  }

  obtenerEvaluacionCurso(tipo:string, idCalendario:string, idAlumno:String){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(`${this.urlApi}encuesta/${tipo}/${idCalendario}/${idAlumno}`,{headers});
  }

  evaluacionesInstructor(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.urlApi+ "instructor/evaluaciones/"+idInstructor,{headers});
  }

  evaluacionesInstructorSinAgrupar(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.urlApi+ "instructor/evaluaciones/todas/"+idInstructor,{headers});
  }

  evaluacionesPorCalendario(idCalendario:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.urlApi+ "encuesta/realizadas/"+idCalendario,{headers});
  }

  detallesEvaluacion(idQuality:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.urlApi+ "encuesta/detalles-respuesta/"+idQuality,{headers});
  }

  realizarEvaluacion(jsonData:any){
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(`${this.urlApi}encuesta/save`, jsonData, {headers});
  }
}

