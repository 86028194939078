export interface Publicacion {
    nombre: string;
    noComentarios: number;
    texto: string;
    fechaHora: string;
    idPublicacion: number;
}

export const mapearResponsePublicacion = (data: any): Publicacion => {
    return {
        nombre: data.nombrePublicacion,
        noComentarios: data.noComentarios,
        texto: data.texto,
        fechaHora: data.fechaHora,
        idPublicacion: data.idPublicacion
    }
}
