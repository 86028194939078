<app-sidebar-menu></app-sidebar-menu>
<div class="pricing-area ptb-100" [class.dark-pricing-area]="themeService.isDark()">
    <div class="container">
        <div class="pricing-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="columna-alumno" scope="col">Alumno</th>
                        <th [class]="anchoColumna"scope="col" *ngFor="let asistencia of asistenciasFecha">
                            <span [class]="classCustom" [ngClass]="{'deshabilitado': rolUsuario !== 'student' && !deshabilitarFecha(asistencia.fecha)}">
                                {{dateService.formatearSoloFecha(asistencia.fecha)}}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="rolUsuario !== 'student'">
                    <tr *ngFor="let usuario of asistenciaUsuarios">
                        <td class="columna-alumno">{{usuario.alumno}} {{usuario.email}}</td>
                        <td  [class]="anchoColumna"*ngFor="let asistencia of asistenciasFecha">
                            <i *ngIf="asistencia.loading && idInscripcionSeleccionado === usuario.idInscripcion" class='bx bx-loader-alt'></i>
                            <input *ngIf="!asistencia.loading" [(ngModel)]="asistencia[usuario.idInscripcion]" type="checkbox" class="form-check-input" (change)="guardarAsistencia(asistencia.fecha, asistencia, usuario.idInscripcion)" [disabled]="!obtenerAsistenciaAdminInstructor(asistencia.fecha, usuario.email, true)">
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rolUsuario === 'student'">
                    <tr *ngFor="let usuario of datosPersonales">
                        <td class="columna-alumno">{{usuario.alumno}} {{usuario.email}}</td>
                        <td [class]="anchoColumna"*ngFor="let asistencia of asistenciasFecha">
                            <i class="bx" [ngClass]="{
                                'bx-check': obtenerAsistenciaEstudiante(asistencia.fecha, usuario.email),
                                'bx-x' : !obtenerAsistenciaEstudiante(asistencia.fecha, usuario.email)
                                }"
                                [ngStyle]="{
                                    'background-color' : !obtenerAsistenciaEstudiante(asistencia.fecha, usuario.email) ? 'red': 'green'
                                }">
                            </i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="rolUsuario !== 'student'">
                <div class="accordion" *ngFor="let usuario of asistenciaUsuarios">
                    <div class="accordion-header">
                        <span>{{usuario.alumno}} {{usuario.email}}</span>
                        <button class="btn btn-sm btn-secondary" (click)="seleccionarCorreo(usuario.email)">
                            <i [class]="(usuario.email === correoSeleccionado ? 'bx bx-chevron-up':'bx bx-chevron-down')"></i>
                        </button>
                    </div>
                    <div class="accordion-content" [ngClass]="{'expand': usuario.email === correoSeleccionado}" *ngFor="let asistencia of asistenciasFecha">
                        <div class="column-one">
                            <strong><span [class]="classCustom" [ngClass]="{'deshabilitado': rolUsuario !== 'student' && !deshabilitarFecha(asistencia.fecha)}">
                                {{dateService.formatearSoloFecha(asistencia.fecha)}}
                            </span></strong>
                        </div>
                        <i *ngIf="asistencia.loading && idInscripcionSeleccionado === usuario.idInscripcion" class='bx bx-loader-alt'></i>
                        <input *ngIf="!asistencia.loading" [(ngModel)]="asistencia[usuario.idInscripcion]" type="checkbox" class="form-check-input" (change)="guardarAsistencia(asistencia.fecha, asistencia, usuario.idInscripcion)" [disabled]="!obtenerAsistenciaAdminInstructor(asistencia.fecha, usuario.email, true)">
                    </div>
                </div>
            </div>
            <div *ngIf="rolUsuario === 'student'">
                <div class="accordion" *ngFor="let usuario of datosPersonales">
                    <div class="accordion-header">
                        <span>{{usuario.alumno}} {{usuario.email}}</span>
                    </div>
                    <div class="accordion-content expand" *ngFor="let asistencia of asistenciasFecha">
                        <div class="column-one">
                            <strong>
                                {{dateService.formatearSoloFecha(asistencia.fecha)}}
                            </strong>
                        </div>
                        <i class="bx" [ngClass]="{
                            'bx-check': obtenerAsistenciaEstudiante(asistencia.fecha, usuario.email),
                            'bx-x' : !obtenerAsistenciaEstudiante(asistencia.fecha, usuario.email)
                            }"
                            [ngStyle]="{
                                'background-color' : !obtenerAsistenciaEstudiante(asistencia.fecha, usuario.email) ? 'red': 'green'
                            }">
                        </i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
