import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';
import { EvaluacionesService } from 'src/app/service/evaluaciones.service';
import { BarController, BarElement, CategoryScale, Chart, ChartConfiguration, LinearScale, registerables } from 'chart.js';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { PaginacionComponent } from "../../common/paginacion/paginacion.component";
import { PaginatePipe } from "../../../pipes/paginate.pipe";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SortingButtonsComponent } from "../../common/sorting-buttons/sorting-buttons.component";
import { SortService } from 'src/app/service/util/sort.service';
import { FilterService } from 'src/app/service/util/filter.service';

@Component({
    selector: 'app-evaluaciones-page',
    standalone: true,
    templateUrl: './evaluaciones-page.component.html',
    styleUrls: ['./evaluaciones-page.component.scss'],
    providers: [BsModalService],
    imports: [HeaderStyleOneComponent, RouterLink, SidebarMenuComponent,
       CommonModule, FormsModule, ReactiveFormsModule, 
      PaginacionComponent, PaginatePipe, SortingButtonsComponent]
})
export class EvaluacionesPageComponent implements OnInit {
  modalRef: any;
  formAbrirDetalles: FormGroup
  currentPage: number = 1
  pageSize: number = 20
  isToggled = false;
  evaluaciones: any;
  evaluacionesSinAgrupar: any;
  tipoUsuario: string;
  chart: Chart | undefined;
  modalRefEvaluaciones: BsModalRef
  nombreCurso: string;
  detallesEvaluaciones:any;
  detalleEvaluacion:any = [];
  datosEncuesta:any = [];
  originalEvaluaciones: any[] = [];
  originalEvaluacionesSinAgrupar: any[] = [];
  isMobile: boolean


  //paginacion modal
  currentPageModalDetalles: number = 1;
  pageSizeModalDetalles: number = 5;

    constructor(
      public themeService: ThemeCustomizerService , private evaluacionesService:EvaluacionesService , 
      private modalService: BsModalService, private sortService: SortService,
      private formBuilder: UntypedFormBuilder, private filterService: FilterService
      
  ) {
      this.themeService.isToggled$.subscribe(isToggled => {
          this.isToggled = isToggled;
      });
      this.tipoUsuario = localStorage.getItem("rolUsuario");
      Chart.register(
        ...registerables,
        CategoryScale,
        LinearScale,
        BarController,
        BarElement
      );
      this.isMobile = window.innerWidth < 576;
      window.onresize = () => {
        this.isMobile = window.innerWidth < 576;
        console.log('ismobile', this.isMobile)
      };  
  }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleExpand(data: any) {
      data.isExpanded = !data.isExpanded;
    }

    ngOnInit(): void {
      this.themeService.isToggled$.subscribe((isToggled) => {
        this.isToggled = isToggled;
        if (this.chart) {
          this.updateChartBackgroundColor();
        }
      });
  
      if (this.tipoUsuario === "admin") {
         this.cargarAdministrador();
      } else if (this.tipoUsuario === "Instructor") {
        this.cargarInstructor();
      }

  }

  private inicializarFormEvaluaciones(datos: any) {
      this.formAbrirDetalles = this.formBuilder.group({
        id: [datos.id],
        nombre: [datos.name],
        email: [datos.email],
        role: [datos.role],
      });
    }

    cargarAdministrador() {
      this.evaluacionesService.todas().subscribe(datosEvaluaciones => {
        this.evaluaciones = datosEvaluaciones;
        this.originalEvaluaciones = [...this.evaluaciones]; // Guardar copia original
        console.log(this.evaluaciones, 'evaluaciones');
      });
    }
    
    cargarInstructor() {
      this.evaluacionesService.evaluacionesInstructor(localStorage.getItem("idInstructor"))
        .subscribe(evaluaciones => {
          this.evaluaciones = evaluaciones;
          this.originalEvaluaciones = [...this.evaluaciones]; // Guardar copia original
          this.cargarGraficasInstructor();
        }, error => {
          console.log(error);
        });
    
      this.evaluacionesService.evaluacionesInstructorSinAgrupar(localStorage.getItem("idInstructor"))
        .subscribe(evaluaciones => {
          this.evaluacionesSinAgrupar = evaluaciones;
          this.originalEvaluacionesSinAgrupar = [...this.evaluacionesSinAgrupar]; // Guardar copia original
        }, error => {
          console.log(error);
        });
    }
  
    cargarGraficasInstructor() {
      const labelsGrafica: any[] = [];
      const promediosIntermedios: any[] = [];
      const promediosFinales: any[] = [];
    
      this.evaluaciones.forEach((evaluacion) => {
        let evaluacionesIntermedias: any[] = [];
        let evaluacionesFinales: any[] = [];
        let totalEvaluacionesIntermedias = 0;
        let totalEvaluacionesFinales = 0;
    
        labelsGrafica.push(evaluacion.nombreCurso);
    
        evaluacion.evaluaciones.forEach((valorEvaluacion) => {
          if (valorEvaluacion.promedioEncuestaIntermedia > 0)
            evaluacionesIntermedias.push(
              parseFloat(valorEvaluacion.promedioEncuestaIntermedia.toFixed(2))
            );
          if (valorEvaluacion.promedioEncuestaFinal > 0)
            evaluacionesFinales.push(
              parseFloat(valorEvaluacion.promedioEncuestaFinal.toFixed(2))
            );
        });
    
        totalEvaluacionesIntermedias = evaluacionesIntermedias.reduce(
          (a, b) => a + b,
          0
        );
        totalEvaluacionesFinales = evaluacionesFinales.reduce((a, b) => a + b, 0);
    
        promediosIntermedios.push(
          (totalEvaluacionesIntermedias / evaluacionesIntermedias.length).toFixed(2)
        );
        promediosFinales.push(
          (totalEvaluacionesFinales / evaluacionesFinales.length).toFixed(2)
        );
      });
    
      const chartData: ChartConfiguration["data"] = {
        labels: labelsGrafica,
        datasets: [
          {
            label: "Promedio intermedio",
            backgroundColor: "#EBCB8B",
            borderColor: "#EBCB8B",
            data: promediosIntermedios,
          },
          {
            label: "Promedio final",
            backgroundColor: "#81A1C1",
            borderColor: "#81A1C1",
            data: promediosFinales,
          },
        ],
      };
    
      const chartOptions: ChartConfiguration["options"] = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: this.themeService.isDark() ? "#ffffff" : "black",
              font: {
                size: this.isMobile ? 10 : 12, // Reducir el tamaño de fuente en dispositivos móviles
              },
            },
          },
          y: {
            grid: {
              display: false,
            },
            min: 50,
            max: 100,
            ticks: {
              color: this.themeService.isDark() ? "#ffffff" : "black",
              font: {
                size: this.isMobile ? 8 : 10, // Reducir el tamaño de fuente en dispositivos móviles
              },
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: this.themeService.isDark() ? "#ffffff" : "black",
              font: {
                size: this.isMobile ? 10 : 12, // Reducir el tamaño de fuente en dispositivos móviles
              },
            },
          },
        },
      };
    
      const canvas = document.getElementById("evaluacionesChart") as HTMLCanvasElement;
      if (canvas) {
        this.chart = new Chart(canvas.getContext("2d"), {
          type: "bar",
          data: chartData,
          options: chartOptions,
        });
    
        // Actualizar el color de fondo y los colores del gráfico
        this.updateChartBackgroundColor();
      }
    }
  
    updateChartBackgroundColor() {
      const canvas = document.getElementById(
        "evaluacionesChart"
      ) as HTMLCanvasElement;
      if (canvas) {
        const context = canvas.getContext("2d");
        if (context) {
          context.canvas.style.backgroundColor = this.themeService.isDark()
            ? "#252525"
            : "#ffffff";
        }
      }
  
      if (this.chart) {
        this.chart.options.scales.x.ticks.color = this.themeService.isDark()
          ? "#ffffff"
          : "black";
        this.chart.options.scales.y.ticks.color = this.themeService.isDark()
          ? "#ffffff"
          : "black";
        this.chart.options.plugins.legend.labels.color = this.themeService.isDark()
          ? "#ffffff"
          : "black";
        this.chart.update();
      }
    }

    filterEvaluaciones(event: KeyboardEvent, field: string) {
      const inputElement = event.target as HTMLInputElement;
      const searchText = inputElement.value;
  
      if (this.tipoUsuario === 'admin') {
        this.evaluaciones = this.filterService.filter(this.originalEvaluaciones, searchText, field);
      } else {
        this.evaluacionesSinAgrupar = this.filterService.filter(this.originalEvaluacionesSinAgrupar, searchText, field);
      }
    }
    
    sortEvaluaciones(prop: string, order: 'asc' | 'desc' | null) {
      if (order === null) {
        return;
      }
      if (this.tipoUsuario === 'admin') {
        this.evaluaciones = this.sortService.sort(this.evaluaciones, prop, order);
        this.evaluaciones = [...this.evaluaciones];
      } else {
        this.evaluacionesSinAgrupar = this.sortService.sort(this.evaluacionesSinAgrupar, prop, order);
        this.evaluacionesSinAgrupar = [...this.evaluacionesSinAgrupar];
      }
    }

    sortDetalles(prop: string, order: 'asc' | 'desc' | null) {
      if (order === null) {
        return;
      }
        this.detallesEvaluaciones = this.sortService.sort(this.detallesEvaluaciones, prop, order);
        this.detallesEvaluaciones = [...this.detallesEvaluaciones];
    }
    instructor: string;
  
    abrirModalDetallesEvaluacion(modalDetalles:any, idCalendario:any, datos:any){
        this.nombreCurso = this.tipoUsuario === 'admin'? datos.curso : datos.nombreCurso;
        this.instructor = this.tipoUsuario === 'admin'? datos.instructor : null
        this.evaluacionesService.evaluacionesPorCalendario(idCalendario).subscribe(detallesEvaluaciones=>{
          this.detallesEvaluaciones = detallesEvaluaciones;
          console.log(detallesEvaluaciones, 'detalles')
            },error => {
                console.log(error)
        });
        this.modalRef = this.modalService.show(modalDetalles, { class: 'modal-lg', backdrop: 'static' });
    }
  
    cerrarModalDetallesEvaluacion(){
        this.modalRef.hide();
    }
  
    cerrarModalEvaluacion(){
        this.modalRefEvaluaciones.hide();
    }
  
    abrirModalEvaluacion(modalEvaluacion:any, idQuality:any){
        this.evaluacionesService.detallesEvaluacion(idQuality).subscribe(detalleEvaluacion=>{
          this.detalleEvaluacion = detalleEvaluacion;
          this.datosEncuesta = detalleEvaluacion["datosEncuesta"][0];
          //console.log("detalles evaluacion ")   
    
          if(this.tipoUsuario === "Instructor"){
            console.log(this.detalleEvaluacion);
            if(this.detalleEvaluacion["tipoEncuesta"]==="Intermedia"){
              //elimina las preguntas que son de evalaucion para tecgurus
              this.detalleEvaluacion["respuestas"][2]["pregunta"]="";
              this.detalleEvaluacion["respuestas"][2]["respuesta"]="";
              this.detalleEvaluacion["respuestas"][4]["pregunta"]="";
              this.detalleEvaluacion["respuestas"][4]["respuesta"]="";
              this.detalleEvaluacion["respuestas"][4]["comentario"]="";
              //recorre numeracion de preguntas por las que se eliminaron
              this.detalleEvaluacion["respuestas"][3]["pregunta"]=
              this.detalleEvaluacion["respuestas"][3]["pregunta"].replace("4)","3)");
             
            }else{
              this.detalleEvaluacion["respuestas"][4]["pregunta"]="";
              this.detalleEvaluacion["respuestas"][4]["respuesta"]="";
              this.detalleEvaluacion["respuestas"][4]["comentario"]="";
        
              this.detalleEvaluacion["respuestas"][5]["pregunta"]="";
              this.detalleEvaluacion["respuestas"][5]["respuesta"]="";
              this.detalleEvaluacion["respuestas"][5]["comentario"]="";
            }
          }
        },error => {
          console.log(error)
        });
        this.modalRefEvaluaciones = this.modalService.show(modalEvaluacion, { class: 'modal-md', backdrop: 'static' });
      }

  abrirModalEvaluaciones(mostrarDetalles: any,datos:any) {
      this.modalRef = this.modalService.show(mostrarDetalles);
      this.inicializarFormEvaluaciones(datos)
      console.log('datos llegan', datos)
    }

    closeModal() {
      this.evaluacionesService = null;
    }

    onPageChange(newPage: number) {
      this.currentPage = newPage;
    }

    onPageChangeModalDetalles(newPage: number) {
      this.currentPageModalDetalles = newPage;
    }
   
}


