import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeCustomizerService {

    private isDarkTheme: boolean;
    private isToggled = new BehaviorSubject<boolean>(false); // Inicializar con el valor actual de isDarkTheme

    constructor() {
        // Obtener el valor del tema del almacenamiento local
        const storedTheme = localStorage.getItem('isDarkTheme');

        // Si hay un valor almacenado, usarlo
        // Si no, establecer un valor predeterminado (por ejemplo, false)
        this.isDarkTheme = storedTheme ? JSON.parse(storedTheme) : false;
        this.isToggled.next(this.isDarkTheme); // Emitir el valor inicial
    }

    toggleTheme() {
        this.isDarkTheme = !this.isDarkTheme;
        localStorage.setItem('isDarkTheme', JSON.stringify(this.isDarkTheme));
        this.isToggled.next(this.isDarkTheme); // Emitir el nuevo valor
        console.log(JSON.stringify(this.isDarkTheme), 'cambio')
    }

    isDark() {
        return this.isDarkTheme;
    }

    get isToggled$() {
        return this.isToggled.asObservable();
    }
}
