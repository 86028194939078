import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PublicacionService {
    private urlApi: string = `${environment.baseApi}`;

    constructor(private http: HttpClient) {}

    async obtenerPublicaciones(idCalendario: number) {
        const headers = this.obtenerHeaders();
        return this.http
            .get(`${this.urlApi}publicacion/calendario/${idCalendario}`, {
                headers,
            })
            .toPromise();
    }

    registrarTarea(formData: any ) {
        return this.http.post(this.urlApi + 'publicacion/save', formData,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}}
        );
      }

    private obtenerHeaders() {
        return new HttpHeaders({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
        });
    }

    eliminarTarea(idPublicacion: number){
        return this.http.delete(this.urlApi + 'publicacion/delete/' + idPublicacion,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json'}}
        );
      }

      obtenerComentarios(idPublicacion:any){
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
        return this.http.get(this.urlApi+ "publicacion/comentarios/"+idPublicacion,{headers});
      }

      registrarComentario(formData: any ) {
        return this.http.post(this.urlApi + 'publicacion/save/comentario', formData,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}}
        );
      }
}
