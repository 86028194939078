<!-- <app-header-style-two></app-header-style-two> -->
<app-sidebar-menu></app-sidebar-menu>
<div class="events-details-area ptb-100" [class.dark-events-details-area]="themeService.isDark()">
    <div class="container">
        <div class="row">
            <div *ngIf="detallesBiblioteca" class="header-box">
                <div class="events-details-header">
                    <a routerLink="/biblioteca" class="back-all-events"><i class='bx bx-chevrons-left'></i> Volver a
                        Biblioteca</a>
                    <h3>{{detallesBiblioteca.nombre}}</h3>
                    <div class="events-meta">
                        <ul>
                            <li>
                                <i class='bx bx-file'></i>
                                <span>Descripcion</span>
                                {{detallesBiblioteca.descripcion}}
                            </li>
                            <li>
                                <i class='bx bx-time'></i>
                                <span>Duracion</span>
                                {{detallesBiblioteca.duracion/60/60|number: '.1-2'}} hrs
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Ultima actualizacion</span>
                                {{formatearFechaConHora(detallesBiblioteca.fechaHora)}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="video-temario-container">
                <div class="video-box-container">
                    <div class="events-details">
                        <h5>{{subtemaSeleccionado.nombre}}</h5>
                        <div class="events-details-video-info">
                            <!-- Contenedor para el iframe -->
                            <div class="video-container">
                                <iframe *ngIf="subtemaSeleccionado.urlVideo" [src]="safeUrl" class="reproductorRecursos"
                                    frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                            <!-- Contenedor para los datos -->
                            <div class="data-container">
                                <span *ngIf="subtemaSeleccionado.fechaHora"><b>Descripcion: </b>
                                    {{subtemaSeleccionado.descripcion}} </span>
                                <span><b>Duracion: </b> {{subtemaSeleccionado.duracion*1000|date: 'mm:ss':'UTC'}} min</span>
                                <span *ngIf="subtemaSeleccionado.fechaHora"><b>Ultima actualizacion:</b>
                                    {{subtemaSeleccionado.fechaHora | date: 'dd/MM/yyyy'}} </span>
                            </div>
                        </div>
    
                        <div >
                        </div>
                    </div>
                </div>
                <div class="temario-container">
                    <aside class="widget-area" >
                        <div class="widget widget_instagram" >
                            <h3 class="widget-title">Temario</h3>
                            <div class="accordion" id="accordionExample">
                                <ng-container *ngFor="let temario of temarios; let i = index">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading{{i}}">
                                            <button class="accordion-button" type="button" (click)="toggleAccordion(i)">
                                                {{ temario.nombreTema }}
                                            </button>
                                        </h2>
                                        <div id="collapse{{i}}" class="accordion-collapse collapse"
                                            [ngClass]="{'show': openAccordionIndex === i}"
                                            [ngClass]="{'show': openAccordionIndex === i, 'dark-accordion-body': themeService.isDark()}">
                                            <div class="accordion-body">
                                                <div *ngFor="let subtema of temario.subtemas;" class="p-grid">
                                                    <div class="p-col-12">
                                                        <span> <b> {{subtema.nombre}} </b> </span> <br />
                                                        <span *ngIf="subtema.duracion >0 ">{{subtema.duracion*1000|date:
                                                            'mm:ss':'UTC'}} min</span> <br>
    
    
                                                        <span *ngIf="subtema.urlVideo != null"
                                                            (click)="cargarRecursoTemario(subtema)" class="links">
                                                            <i class="bx bx-play"></i>Ver leccion
                                                            <img *ngIf="subtema.visto ==true"
                                                                src="assets/img/gurusin-like3.png" width="25
                                                            px" height="25px" /><br />
    
                                                        </span>
    
    
                                                        <a *ngIf="subtema.urlArchivo != null"
                                                            href="{{urlArchivosBiblioteca+subtema.urlArchivo}}" download
                                                            target="_blank" class="links">
                                                            <i class="pi pi-download"></i> {{subtema.urlArchivo}} <br />
                                                        </a>
    
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </aside>
    
                </div>
            </div>
           
        </div>
    </div>
</div>