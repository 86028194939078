<div class="sidebar" [class.open]="isOpen" [class.dark-sidebar]="isDarkTheme" (mouseleave)="!isMobile && closeMenu()">
  <div class="sidebar-header">
    <img src="assets/img/logo64.png" alt="logo">
    <h3 [class.white-theme]="!isDarkTheme" [class.black-theme]="isDarkTheme">TG Alumnos</h3>
  </div>
  <ul class="sidebar-menu" style="cursor: pointer;">
    <ng-container *ngFor="let item of getMenuItems()">
      <li [class.open]="item.isOpen">
        <ng-container *ngIf="isInternalLink(getRouterLink(item)); else externalLink">
          <a [class.white-theme]="!isDarkTheme" [class.black-theme]="isDarkTheme"
             [routerLink]="getRouterLink(item)"
             (click)="handleClick(item, $event)">
            {{ item.title }}
            <span class="bx bx-chevron-down" *ngIf="item.subItems && !item.isOpen"></span>
            <span class="bx bx-chevron-up" *ngIf="item.subItems && item.isOpen"></span>
          </a>
        </ng-container>
        <ng-template #externalLink>
          <a [class.white-theme]="!isDarkTheme" [class.black-theme]="isDarkTheme"
             [href]="getRouterLink(item)"
             target="_blank">
            {{ item.title }}
            <span class="bx bx-chevron-down" *ngIf="item.subItems && !item.isOpen"></span>
            <span class="bx bx-chevron-up" *ngIf="item.subItems && item.isOpen"></span>
          </a>
        </ng-template>
        <ul *ngIf="item.subItems">
          <li *ngFor="let subItem of item.subItems">
            <ng-container *ngIf="isInternalLink(subItem.link); else externalSubLink">
              <a [class.white-theme]="!isDarkTheme" [class.black-theme]="isDarkTheme"
                 [routerLink]="subItem.link"
                 (click)="handleClick(subItem, $event)">
                {{ subItem.label }}
              </a>
            </ng-container>
            <ng-template #externalSubLink>
              <a [class.white-theme]="!isDarkTheme" [class.black-theme]="isDarkTheme"
                 [href]="subItem.link"
                 target="_blank">
                {{ subItem.label }}
              </a>
            </ng-template>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
  <app-theme-customizer></app-theme-customizer>
</div>

<button class="open-sidebar-btn" *ngIf="!isOpen"
        [class.white-theme]="isDarkTheme && !isOpen"
        [class.black-theme]="!isDarkTheme && !isOpen"
        (mouseenter)="!isMobile && openMenu()"
        (click)="isMobile && openMenu()">☰</button>



  
