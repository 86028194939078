<div class="sorting-buttons" >
  <button class="button-arrow" (click)="toggleSortOrder('asc')" [ngClass]="{'active': sortOrder === 'asc', 'text-light': isDark}">
    <i class="bx bx-up-arrow-circle"></i>
  </button>
  <button class="button-arrow" (click)="toggleSortOrder('desc')" [ngClass]="{'active': sortOrder === 'desc', 'text-light': isDark}">
    <i class="bx bx-down-arrow-circle"></i>
  </button>
</div>

  
  
  

  
