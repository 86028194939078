import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginacionService {
  paginaActual: number = 1;
  elementosPorPagina: number = 12;

  establecerPaginaActual(page: number) {
    this.paginaActual = page;
  }

  establecerElementosPorPagina(items: number) {
    this.elementosPorPagina = items;
  }

  obtenerRangoInicio(): number {
    return (this.paginaActual - 1) * this.elementosPorPagina + 1;
  }

  obtenerRangoFin(totalItems: number): number {
    const end = this.paginaActual * this.elementosPorPagina;
    return end > totalItems ? totalItems : end;
  }

  obtenerTotalPaginas(totalItems: number): number {
    return Math.ceil(totalItems / this.elementosPorPagina);
  }

  obtenerPaginasVisibles(totalItems: number): (number | string)[] {
    const totalPages = this.obtenerTotalPaginas(totalItems);
    const visiblePages: (number | string)[] = [];

    visiblePages.push(1);

    if (totalPages <= 10) {
      for (let i = 2; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      const startPage = Math.max(2, this.paginaActual - 2);
      const endPage = Math.min(totalPages - 1, this.paginaActual + 2);

      if (startPage > 2) {
        visiblePages.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }

      if (endPage < totalPages - 1) {
        visiblePages.push('...');
      }

      visiblePages.push(totalPages);
    }

    return visiblePages;
  }

  actualizarElementosPagina<T>(items: T[]): T[] {
    const start = (this.paginaActual - 1) * this.elementosPorPagina;
    const end = start + this.elementosPorPagina;
    return items.slice(start, end);
  }
}
