import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UsuarioAlumnosLogin } from "../interface/usuario-alumno";

@Injectable({
  providedIn: "root",
})
export class IniciarSesionService {
  private urlApi: string = `${environment.baseApi}`;

  constructor(private http: HttpClient, private router: Router) {}

  login(usuario: UsuarioAlumnosLogin) {
    const jsonData = JSON.stringify({
      username: usuario.email,
      password: usuario.password,
    });
    const headers = new HttpHeaders({"Content-Type": "application/json;charset=UTF-8",});
    return this.http.post(`${this.urlApi}login/authenticate`, jsonData, { headers });
  }

  correoRecuperacion(formData: any) {
    return this.http.post(`${this.urlApi}login/forgot-password`, formData);
  }

  restablecerContrasena(formData: any) {
    return this.http.post(`${this.urlApi}login/resset-password`, formData);
  }

  logout() {
    localStorage.removeItem("idUsuario");
    localStorage.removeItem("idInstructor");
    localStorage.removeItem("idAlumno");
    localStorage.removeItem("nombreUsuario");
    localStorage.removeItem("email");
    localStorage.removeItem("rolUsuario");
    localStorage.removeItem("token");
    this.router.navigateByUrl("/iniciar-sesion");
  }
}
