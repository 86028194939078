import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MenuItem } from 'src/app/components/common/sidebar-menu/menu-items';

@Injectable()
export class BreadcrumbsService {
  private itemsSubject = new BehaviorSubject<MenuItem[]>([]);
  itemsHandler = this.itemsSubject.asObservable();
  private routeHistory: MenuItem[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.buildBreadcrumb(this.activatedRoute.root))
      )
      .subscribe(breadcrumbs => {
        this.updateBreadcrumbs(breadcrumbs);
      });
  }

  private buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      let breadcrumbLabel = label;

      const routeParams = child.snapshot.params;
      if (label && routeParams) {
        breadcrumbLabel = label.replace(/:([^\/]+)/g, (match, paramName) => routeParams[paramName]);
      }

      if (breadcrumbLabel !== undefined) {
        breadcrumbs.push({ title: breadcrumbLabel, link: url });
      }

      return this.buildBreadcrumb(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  private updateBreadcrumbs(newBreadcrumbs: MenuItem[]): void {
    const currentUrl = this.router.url;

    // Borrar historial si la ruta actual es la página de inicio
    if (currentUrl === '/') {
      this.routeHistory = [];
    } else {
      const hasParams = currentUrl.includes('%');
  
      if (hasParams) {
        const currentRouteIndex = this.routeHistory.findIndex(item => item.link === currentUrl);
  
        if (currentRouteIndex !== -1) {
          this.routeHistory = this.routeHistory.slice(0, currentRouteIndex + 1);
        }
      } else {
        const lastNonParamIndex = this.routeHistory.findIndex(item => !item.link.includes(':'));
        if (lastNonParamIndex !== -1) {
          this.routeHistory = this.routeHistory.slice(0, Math.min(lastNonParamIndex + 1, this.routeHistory.length));
        }
      }
    }

    // Manejar casos específicos
    const bibliotecaIndex = this.routeHistory.findIndex(item => item.link.includes('/biblioteca'));
    const cursoIndex = this.routeHistory.findIndex(item => item.link.includes('/curso'));

    if (currentUrl.includes('/biblioteca/detalles') && bibliotecaIndex === -1) {
      this.routeHistory.push({ title: 'Biblioteca', link: '/biblioteca' });
    } else if (currentUrl.includes('/curso/') && !currentUrl.includes('material') && cursoIndex === -1) {
      this.routeHistory.push({ title: 'Cursos', link: '/cursos' });
    }

    this.routeHistory = [...this.routeHistory, ...newBreadcrumbs];
    this.itemsSubject.next(this.routeHistory);
  }
}








