import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appConfig } from './app/app.config';
import { BreadcrumbsService } from './app/service/util/breadcrumbs.service';

bootstrapApplication(AppComponent, {
    providers: [
        ...appConfig.providers,
        provideAnimations(),
        provideToastr(),
        BreadcrumbsService
    ]
})
.catch((err) => console.error(err));