import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-paginacion",
  standalone: true,
  templateUrl: "./paginacion.component.html",
  styleUrls: ["./paginacion.component.scss"],
  imports: [CommonModule]
})
export class PaginacionComponent implements OnInit {
  @Input() paginaActual: number = 1;
  @Input() elementosPorPagina: number = 12;
  @Input() totalElemetos: number;

  @Output() cambioPagina: EventEmitter<number> = new EventEmitter<number>();
  constructor() {}

  ngOnInit() {}

  get totalPaginas(): number {
    return Math.ceil(this.totalElemetos / this.elementosPorPagina);
  }

  goToPage(page: number) {
    if (this.paginaActual <= this.totalPaginas) {
      this.paginaActual = page;
      this.cambioPagina.emit(this.paginaActual);
    }
  }

  getVisiblePages(): (number | string)[] {
    const totalPages = this.totalPaginas;
    const visiblePages: (number | string)[] = [];

    visiblePages.push(1);

    if (totalPages <= 10) {
      for (let i = 2; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      const startPage = Math.max(2, this.paginaActual - 2);
      const endPage = Math.min(totalPages - 1, this.paginaActual + 2);

      if (startPage > 2) {
        visiblePages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }

      if (endPage < totalPages - 1) {
        visiblePages.push("...");
      }

      visiblePages.push(totalPages);
    }

    return visiblePages;
  }

}
