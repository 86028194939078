import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { UsuarioService } from 'src/app/service/usuario.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UsuarioModel } from 'src/app/interface/usuario';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-edit-account-page',
    standalone: true,
    templateUrl: './edit-account-page.component.html',
    styleUrls: ['./edit-account-page.component.scss'],
    imports: [RouterLink, SidebarMenuComponent, HeaderStyleOneComponent,
        CommonModule, FormsModule
    ]
})
export class EditAccountPageComponent implements OnInit {

    isToggled = false;
	
    statusUpdatePerfil:string = 'void';
    usuario:UsuarioModel;
    tokenUsuario:string;nombreUsuario:string;email:String; rolUsuario:string;
  
    /*variable para traduccion*/
    lang: any;
    
    constructor(private usuarioService:UsuarioService, public themeService: ThemeCustomizerService,
        private toastr: ToastrService) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
  
      var datosTemp:any;
      this.usuario = new UsuarioModel();
      this.usuarioService.perfilCompleto(parseInt(localStorage.getItem("idUsuario"))).subscribe(datos =>{
        datosTemp = datos;
        console.log('datos', datosTemp)
        this.usuario.id = datosTemp.id;
        this.usuario.nombreUsuario = datosTemp.username;
        this.usuario.email = datosTemp.email;
        this.usuario.rol = datosTemp.rol;
  
        this.usuario.idAlumno = datosTemp.idAlumno;
        this.usuario.idInstructor = datosTemp.idInstructor;
        this.usuario.name = datosTemp.name? datosTemp.name : '';
        this.usuario.phone = datosTemp.phone;
        this.usuario.createdAt = datosTemp.createdAt;
        this.usuario.updatedAt = datosTemp.updatedAt;
        this.usuario.deletedAt = datosTemp.deletedAt;
        this.usuario.address = datosTemp.address;
      });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        this.tokenUsuario = localStorage.getItem("token");
        this.nombreUsuario = localStorage.getItem("nombreUsuario");
        this.email = localStorage.getItem("email");
        this.rolUsuario = localStorage.getItem("rolUsuario");
    }

    solicitarActualizarInformacion(){
        this.toastr.success("Solicitud aprobada!", "Exito")
      }
    
      actualizarInformacion(){
        let datosUsuario:FormData = new FormData();
        datosUsuario.append('id', String(this.usuario.id));
        datosUsuario.append('name', String(this.usuario.name));
        datosUsuario.append('phone', String(this.usuario.phone));
        datosUsuario.append('address', String(this.usuario.address));
        this.usuarioService.actualizarDatosPersonales(datosUsuario).subscribe(datos =>{
          this.statusUpdatePerfil = 'true';
          this.toastr.success("Se han actualizado los datos correctamente", "Exito")
        }, error => {
          this.toastr.error("No es posible actualizar los datos", "Error")
        });
      }

      validarNumeros(event: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']; // Permitir teclas de control
        if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
          return;
        }
        if (!allowedKeys.includes(event.key) && isNaN(Number(event.key))) {
          event.preventDefault(); // Prevenir la entrada de caracteres no numéricos
        }
      }
}