import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginate',
  standalone: true
})
export class PaginatePipe implements PipeTransform {

  transform(elementos: any[], pagina: number = 1, elementosPorPagina: number = 12): any[] {
    if(!elementos || elementos.length === 0){
        return [];
    }
    const start = (pagina - 1) * elementosPorPagina;
    const end = start + elementosPorPagina;
    return elementos.slice(start, end);
  }

}
