import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../theme-customizer/theme-customizer.service";
import { Router, RouterLink } from "@angular/router";
import { UsuarioAlumnosLogin } from "src/app/interface/usuario-alumno";
import { IniciarSesionService } from "src/app/service/iniciar-sesion.service";
import { catchError, throwError } from "rxjs";
import { FormsModule } from "@angular/forms";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { CommonModule } from "@angular/common";

const correoRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: "app-iniciar-sesion-page",
  standalone: true,
  imports: [RouterLink, FormsModule, ToastrModule, CommonModule],
  templateUrl: "./iniciar-sesion-page.component.html",
  styleUrls: ["./iniciar-sesion-page.component.scss"],
})
export class IniciarSesionPageComponent implements OnInit {
  isToggled = false;
  loginForm: UsuarioAlumnosLogin;
  enviadoForm: boolean = false;
  passwordVisible: boolean;
  showImage: boolean;
  constructor(
    public themeService: ThemeCustomizerService,
    private toastrService: ToastrService,
    private router: Router,
    private iniciarSesionService: IniciarSesionService
  ) {
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
    this.showImage = window.innerWidth < 992;
    window.onresize = () => {
      this.showImage = window.innerWidth < 992;
      console.log('ismobile', this.showImage)
    };
    this.loginForm = { email: null, password: null };
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {}

  iniciarSesion() {
    this.enviadoForm = true;
    const usuario: UsuarioAlumnosLogin = this.validarFormulario();

    if (usuario === null) {
      this.toastrService.error(
        "El usuario o contraseña no son validos.",
        "Error Datos formularios"
      );
      return;
    }

    this.iniciarSesionService
      .login(usuario)
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.toastrService.error(
              "El usuario o contraseña son erroneos.",
              "Accesos Incorrectos"
            );
          } else {
            this.toastrService.error(
              "Por el momento el servicio no esta listo.",
              "Servicio Inactivo"
            );
          }
          this.enviadoForm = false;

          return throwError(error);
        })
      )
      .subscribe((datosUsuario) => {
        localStorage.setItem("idUsuario", datosUsuario["idUsuario"]);
        localStorage.setItem("idInstructor", datosUsuario["idInstructor"]);
        localStorage.setItem("idAlumno", datosUsuario["idAlumno"]);
        localStorage.setItem("nombreUsuario", datosUsuario["userName"]);
        localStorage.setItem("email", datosUsuario["email"]);
        localStorage.setItem("rolUsuario", datosUsuario["role"]);
        localStorage.setItem("token", datosUsuario["token"]);
        localStorage.setItem(
          "expiracionToken",
          datosUsuario["fechaExpiracionToken"]
        );
        if (datosUsuario["role"] === "library") {
          this.router.navigateByUrl("/biblioteca");
        } else {
          this.router.navigateByUrl("/");
        }
        this.enviadoForm = false;
      });
  }

  private validarFormulario(): UsuarioAlumnosLogin {
    if (
      this.loginForm.email === null ||
      this.loginForm.email === "" ||
      !this.loginForm.email.match(correoRegExp) ||
      this.loginForm.password === null ||
      this.loginForm.password === "" ||
      this.loginForm.password.length < 2
    ) {
      this.enviadoForm = false;
      return null;
    }

    const login: UsuarioAlumnosLogin = {
      email: this.loginForm.email.split(" ").join(""),
      password: this.loginForm.password.split(" ").join(""),
    };

    return login;
  }

  get passwordFieldType(): string {
    return this.passwordVisible ? "text" : "password";
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }
}
