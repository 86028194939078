<!-- Header -->
<header class="header-area p-relative">

    <!-- Top Header -->
    <!-- <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Call:
                            <a href="tel:+502464674">+502 464 674</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

   <!-- Navbar -->
<div
class="navbar-area navbar-style-three"
[ngClass]="{'sticky': isSticky}"
[class.dark-navbar-area]="themeService.isDark()"
>
<div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
        <!-- Logo -->
        <a
    class="navbar-brand"
    routerLink="/"
    [class.black-theme]="!isToggled" [class.white-theme]="isToggled"
>
    <img src="assets/img/logo64.png" alt="logo">
    <span *ngIf="!isMobile">TG Alumnos</span>
</a>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <!-- Eliminando elementos del menú -->
                <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-chevron-down'></i></a> -->
                <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a> -->
                <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses <i class='bx bx-chevron-down'></i></a> -->
                <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a> -->
                <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-down'></i></a> -->
                <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->
            </ul>
        </div>
        <div class="others-option">
            <!-- <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                    <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                    <span>Eng <i class='bx bx-chevron-down'></i></span>
                </button>
                <div class="dropdown-menu">
                    <a href="#" class="dropdown-item d-flex align-items-center">
                        <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                        <span>Ger</span>
                    </a>
                    <a href="#" class="dropdown-item d-flex align-items-center">
                        <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                        <span>Fre</span>
                    </a>
                    <a href="#" class="dropdown-item d-flex align-items-center">
                        <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                        <span>Spa</span>
                    </a>
                    <a href="#" class="dropdown-item d-flex align-items-center">
                        <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                        <span>Rus</span>
                    </a>
                    <a href="#" class="dropdown-item d-flex align-items-center">
                        <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                        <span>Ita</span>
                    </a>
                </div>
            </div>
            <div class="search-box d-inline-block">
                <i class='bx bx-search' (click)="toggleClass3()"></i>
            </div> -->
            <div class="dropdown" (click)="toggleNotificationDropdown()">
                <a class="cart-wrapper-btn dropdown-toggle" role="button" id="notificationDropdown" aria-expanded="false">
                    <i class="bx bx-bell"></i>
                    <span>{{notificacionesNuevas}}</span>
                </a>
                <ul class="dropdown-menu notification-dropdown" 
                    [class.show]="isNotificationDropdownOpen" 
                    [class.black-dropdown]="isToggled" 
                    [class.white-dropdown]="!isToggled">
                    
                    <li *ngFor="let notificacion of notificacionesToShow; let i = index">
                        <a class="dropdown-item" href="#" 
                            [ngClass]="{
                                'black-dropdown-item': isToggled, 
                                'white-dropdown-item': !isToggled,
                                'notification-unread': !notificacion.vista, 
                            }">
                            <div class="card-body">
                                <h6 class="card-subtitle text-body-secondary">
                                    <span [class.title-text]="isToggled" [class.title-text-normal]="!isToggled">
                                        {{notificacion.tipoNotificacion}} en {{notificacion.curso}}
                                    </span>
                                    <span class="notification-date-time">{{notificacion.fechaHora | date: 'dd/MM/yyyy hh:mm a'}}</span>
                                </h6>
                                <p class="card-text">{{notificacion.texto}}</p>
                                <span class="user-notification">Por: {{notificacion.usuario}}</span>
                                <div class="card-links">
                                    <a class="card-link" [ngClass]="{'disabled': notificacion.vista}"
                                        (click)="marcarComoLeido(notificacion); $event.stopPropagation()">
                                        Marcar leído
                                    </a>
                                    <!-- <a class="card-link">Borrar</a> -->
                                    
                                </div>
                            </div>
                        </a>
                        <hr class="dropdown-divider" *ngIf="notificacionesToShow.length > 1 && i < notificacionesToShow.length - 1">
                    </li>
                    
                    <div style="text-align: center;" *ngIf="notificaciones.length > notificacionesToShow.length">
                        <a (click)="verMasNotificaciones($event)" style="cursor: pointer;">
                            <h3 class="notification-text">Ver más notificaciones</h3>
                        </a>
                    </div>
                    <div style="text-align: center;" *ngIf="notificaciones.length === 0">
                        <h3 class="notification-text">No hay notificaciones</h3>
                    </div>
                </ul>
            </div>
            
            
            
            
            <div class="dropdown" (click)="toggleUserDropdown()">
                <div style="display: inline-flex; justify-content: center; align-items: center;">
                    <a class="cart-wrapper-btn d-inline-block dropdown-toggle" role="button" id="userDropdown" aria-expanded="false">
                        <i class='bx bx-user'></i>
                    </a>
                </div>
                <ul class="dropdown-menu user-dropdown" [class.show]="isUserDropdownOpen" 
                aria-labelledby="userDropdown" [class.black-dropdown]="isToggled">
                    <li>
                            <div style="display: inline-flex; align-items: center;">
                                <img src="https://www.w3schools.com/w3images/avatar2.png" 
                                alt="Imagen de perfil" style="width: 40px; height: 40px; border-radius: 50%;
                                 margin-right: 10px;">
                                 <div style="display: flex; flex-direction: column; width: 80%;">
                                    <label style="font-size: 14px;"  [ngClass]="{'dark-name': themeService.isDark()}">{{nombreUsuario}}</label>
                                    <span style="font-size: 12px; color: gray;">{{rolUsuario}}</span>
                                 </div>
                        </div>
                    </li>
                    <hr class="dropdown-divider">
                    <li routerLink="/perfil"><a>
                        <span style="cursor:pointer" [class.title-text]="isToggled" 
                        [class.title-text-normal]="!isToggled">Perfil</span>
                    </a></li>
                    <hr class="dropdown-divider">
                    <li (click)="cerrarSesison()"><a>
                        <span style="cursor:pointer" [class.title-text]="isToggled"
                        [class.title-text-normal]="!isToggled">Cerrar Sesion</span>
                    </a></li>
                </ul>
            </div>
        </div>
    </nav>
</div>
</div>


</header>

<!-- Search Overlay -->
<div
    class="search-overlay"
    [class.active]="classApplied3"
    [class.dark-search-overlay]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
