<app-sidebar-menu></app-sidebar-menu>

<div class="courses-area ptb-100" [class.dark-courses-area]="themeService.isDark()">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center container-select-search">
                <div class="col-lg-8 col-md-8 select-search">
                    <div class="col-lg-3 col-md-5 offset-lg-0 offset-md-1">
                        <div class="topbar-ordering">
                            <select [disabled]="encuestaPendiente" class="form-select" [(ngModel)]="opcionSeleccionada" (change)="filtro($event.target.value)">
                                <option *ngFor="let opcion of opcionesFiltro" [value]="opcion">{{opcion}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="encuestaPendiente" class="alert alert-info alert-dismissible fade show" role="alert">
            Uno de los principales objetivos de Tec Gurus es el de ofrecer siempre la máxima calidad en los servicios otorgados a nuestros clientes para que se encuentren satisfechos de habernos elegido. Por este motivo tu retroalimentación es muy importante para nosotros, te pedimos por favor nos apoyes contestando la encuesta de satisfacción ya que es requisito para continuar viendo el material de tu curso.
            <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">x</span>
            </button> -->
        </div>
        <div class="row cursos-order" style="gap: 1%;">
            <div class="col-lg-4 col-md-6 curso-contenedor" [ngClass]="{'admin': ((rolUsuario === 'admin' && !cursosIniciados) || cursosProximos)}" *ngFor="let curso of cursos | paginate : currentPage : pageSize">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a  class="d-block"><img src="{{curso.banner}}" alt="image"></a>
                        <div class="courses-tag" *ngIf="(rolUsuario !== 'admin' || cursosIniciados) && !cursosProximos">
                            <a class="d-block">{{ dateService.formatearFecha(curso.fechaInicio, curso.fechaFin) }}</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <h3><a class="d-inline-block">
                            {{((rolUsuario === 'admin' && !cursosIniciados) || cursosProximos) ? reducirTexto(curso.nombre) : curso.nombre}}
                        </a></h3>
                    </div>
                    <div class="courses-box-footer" >
                        <ul style="display: inline;"  *ngIf="rolUsuario === 'admin' && cursosIniciados">
                            <li class="course-location">
                              <i class='bx bx-user-circle'></i> {{curso.instructor}}
                            </li>
                          </ul>
                        <ul style="display: inline;" *ngIf="rolUsuario !== 'admin' && !cursosProximos">
                            <li class="course-modality">
                                <i class='bx bx-time-five'></i> {{ curso.modalidad + ' - ' + curso.horaInicio }}
                            </li>
                            <li class="course-status">
                                <i class='bx' [ngClass]="{
                                    'bx-x-circle': curso.statusCalendario === 'Cancelado',
                                    'bx-check-circle': curso.statusCalendario === 'Confirmado',
                                    'bx-play-circle': curso.statusCalendario === 'Iniciado',
                                    'bx-minus-circle': curso.statusCalendario === 'No aplica',
                                    'bx-calendar': curso.statusCalendario === 'Planeado',
                                    'bx-stop-circle': curso.statusCalendario === 'Terminado'
                                }"></i> {{ curso.statusCalendario }}
                            </li>
                        </ul>
                    </div>
                    <div class="footer-buttons">
                        <button class="btn btn-sm btn-primary order-button-icon" (click)="redirigirDetalles(curso)"
                        *ngIf="curso.status !== 'Terminado' && (!curso.encuestaIntermedia && !curso.encuestaFinal)">
                          <i class="bx" [ngClass]="{
                            'bx-right-arrow': !cursosProximos,
                            'bx-error-circle': cursosProximos
                          }"></i> {{cursosProximos ? "Detalles" :
                            (rolUsuario !== 'admin' || cursosIniciados) ? "Ir al curso" : "Ver Material"}}
                        </button>
                        <button class="btn btn-sm btn-success order-button-icon" *ngIf="curso.status === 'Terminado' &&
                            (!curso.encuestaIntermedia && !curso.encuestaFinal)">
                            <i class='bx bx-medal'></i> Ver diploma
                        </button>
                        <button class="btn btn-sm btn-warning order-button-icon" (click)="mostrarEvaluaciones('Intermedia',curso.idCalendario)" *ngIf="curso.encuestaIntermedia" >
                            <i class='bx bx-clipboard'></i> Realizar encuesta intermedia
                        </button>
                        <button class="btn btn-sm btn-warning order-button-icon" (click)="mostrarEvaluaciones('Final ',curso.idCalendario)" *ngIf="curso.encuestaFinal">
                            <i class='bx bx-clipboard'></i> Realizar encuesta final
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <app-paginacion *ngIf="cursos.length > pageSize" [paginaActual]="currentPage"
        [elementosPorPagina]="pageSize"
        [totalElemetos]="cursos.length"
        (cambioPagina)="onPageChange($event)">
    </app-paginacion>
    </div>
</div>

<app-modal-detalles [curso]="cursoSeleccionado"></app-modal-detalles>

<app-modal-evaluaciones [idCalendario]="idCalendarioEvaluacion" [tipoEvaluacion]="tipoEvaluacionSeleccionado" (accionEncuesta)="realiarAccion($event)"></app-modal-evaluaciones>

<app-modal-promociones></app-modal-promociones>