<app-sidebar-menu></app-sidebar-menu>
<div class="my-dashboard-area ptb-100" [class.dark-my-dashboard-area]="themeService.isDark()">
  <div class="container">
    <div class="myDashboard-content">
      <div class="header">
        <h4>Materiales del curso: {{nombreCurso}}</h4>
        <button class="btn btn-primary" (click)="abrirModalNuevoMaterial(nuevoMaterial)">Nuevo Material</button>
      </div>
      <div class="recent-orders-table table-responsive">
        <table class="table table-bordered" [ngClass]="{'table-dark': themeService.isDark()}">
          <thead class="table-light" [ngClass]="{'table-dark': themeService.isDark()}">
            <tr>
              <th>
                Nombre
                <button class="button-arrow" (click)="sortMateriales('nombre', 'asc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-up-arrow-circle"></i></button>
                <button class="button-arrow" (click)="sortMateriales('nombre', 'desc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-down-arrow-circle"></i></button>
                <input type="text" class="filter-input" (keyup)="filterMateriales($event, 'nombre')"
                  placeholder="Filtrar por Nombre">
              </th>
              <th>
                Tipo
                <button class="button-arrow" (click)="sortMateriales('tipoMaterial', 'asc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-up-arrow-circle"></i></button>
                <button class="button-arrow" (click)="sortMateriales('tipoMaterial', 'desc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-down-arrow-circle"></i></button>
                <input type="text" class="filter-input" (keyup)="filterMateriales($event, 'tipoMaterial')"
                  placeholder="Filtrar por Tipo">
              </th>
              <th>
                Fecha
                <button class="button-arrow" (click)="sortMateriales('fechaHora', 'asc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-up-arrow-circle"></i></button>
                <button class="button-arrow" (click)="sortMateriales('fechaHora', 'desc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-down-arrow-circle"></i></button>
              </th>
              <th>
                Subido por
                <button class="button-arrow" (click)="sortMateriales('users.name', 'asc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-up-arrow-circle"></i></button>
                <button class="button-arrow" (click)="sortMateriales('users.name', 'desc')"
                  [ngClass]="{'text-light': themeService.isDark()}">
                  <i class="bx bx-down-arrow-circle"></i></button>
                <input type="text" class="filter-input" (keyup)="filterMateriales($event, 'users.name')"
                  placeholder="Filtrar por Subido por">
              </th>
              <th>
                Compartir/No compartir
                <select class="filter-select" (change)="filterByPublicado($event)">
                  <option value="">Todos</option>
                  <option value="true">Compartir</option>
                  <option value="false">No compartir</option>
                </select>
              </th>
              <th>Visualizar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of paginatedMateriales">
              <td>{{data.nombre}}</td>
              <td>{{data.tipoMaterial}}</td>
              <td>{{fechasService.formatearFechaConHora(data.fechaHora)}}</td>
              <td>{{data.users.name}}</td>
              <td class="text-center">
                <input *ngIf="data.publicado === false" type="checkbox"
                (click)="compartirMaterial(data.idMaterial)" name="groupname-{{data.idMaterial}}">
                <input *ngIf="data.publicado === true" type="checkbox" checked
                (click)="eliminarMaterialCompartido(data.idMaterialCompartido)" name="groupname-{{data.idMaterial}}">
              </td>
              <td>
                <a [href]="data.archivo" 
                   style="text-decoration: none;" 
                   target="_blank" 
                   class="btn btn-sm btn-primary" 
                   (click)="removeFocus($event)">
                  <i class='bx bx-show'></i>
                </a>
              </td>              
              <td><button class="btn btn-sm btn-danger" [disabled]="!validarMiMaterial(data) === true"
                  (click)="eliminarMaterial(data)"><i class='bx bx-trash'></i></button></td>
            </tr>
          </tbody>
        </table>

        <!-- Estructura para dispositivos móviles -->
        <div *ngIf="isMobile">
          <div *ngFor="let material of paginatedMateriales" class="accordion">
            <div class="accordion-header" (click)="toggleExpand(material)">
              <span>{{material.nombre}}</span>
              <button class="btn btn-sm btn-secondary">
                <i [class]="material.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
              </button>
            </div>
            <div class="accordion-content" *ngIf="material.isExpanded">
              <strong>Tipo: </strong>{{material.tipoMaterial}}<br>
              <strong>Fecha: </strong>{{fechasService.formatearFechaConHora(material.fechaHora)}}<br>
              <strong>Subido por: </strong>{{material.users.name}}<br>
              <strong>Compartir/ No compartir: </strong> <input type="checkbox" [checked]="material.publicado"><br>
              <div>
                <strong>Visualizar:</strong>
                <a [href]="material.archivo" target="_blank" class="btn btn-sm btn-primary">
                  <i class='bx bx-show'></i>
                </a>
              </div>
              <strong>Eliminar:</strong>
              <div><button class="btn btn-sm btn-danger" [disabled]="!validarMiMaterial(material) === true"
                  (click)="eliminarMaterial(material)"><i class='bx bx-trash'></i></button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="paginatedMateriales.length > 0">
        <div class="pagination-area text-center">
          <a *ngFor="let page of getVisiblePages(); let i = index" (click)="page !== '...' ? goToPage(page) : null"
            class="page-numbers" [class.current]="currentPage === page" [class.disabled]="page === '...'">
            {{page}}
          </a>
          <a (click)="goToPage(currentPage + 1)" class="page-numbers next"
            [class.disabled]="currentPage === getTotalPages()">
            <i class='bx bx-chevron-right'></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- MODAL NUEVO MATERIAL -->
<ng-template #nuevoMaterial role="document" let-modal>
  <div class="modal-new" [class.dark-modal]="themeService.isDark()">
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel" [ngClass]="{'dark-modal-title': themeService.isDark()}">Nuevo material
        del curso</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="cerrarModal()"></button>
    </div>
    <div class="modal-body">
      <span class="text-danger">*Campos requeridos | Peso máximo de archivos 100Mb</span>
      <form #materialForm="ngForm" (ngSubmit)="guardarNuevoMaterial(materialForm)">
        <div class="mb-3">
          <label>Nombre*</label>
          <input class="form-control" type="text" [(ngModel)]="nombre" name="nombre" required #nombreInput="ngModel">
          <div *ngIf="nombreInput.invalid && nombreInput.touched" class="text-danger">Nombre es requerido.</div>
        </div>
        <div class="mb-3">
          <label>Tipo*</label>
          <select class="form-select" [(ngModel)]="tipo" name="tipo" required #tipoInput="ngModel">
            <option *ngFor="let tipo of tiposMaterial" [value]="tipo.idTipoMaterial">{{tipo.nombre}}</option>
          </select>
          <div *ngIf="tipoInput.invalid && tipoInput.touched" class="text-danger">Tipo es requerido.</div>
        </div>
        <div class="mb-3">
          <label>Descripcion*</label>
          <textarea class="form-control" [(ngModel)]="descripcion" name="descripcion" required
            #descripcionInput="ngModel"></textarea>
          <div *ngIf="descripcionInput.invalid && descripcionInput.touched" class="text-danger">Descripción es
            requerida.</div>
        </div>
        <div class="mb-3">
          <label>Compartir en la clase:</label>
          <input class="form-check" type="checkbox" [(ngModel)]="compartirMarteialAlSubir" name="compartir">
        </div>
        <div class="mb-3">
          <div class="mb-3 contenedor-total-archivos">
            <label>Subir archivos*</label>
            <div class="custom-file-upload" [ngClass]="{'dark-theme': themeService.isDark()}">
              <label for="file-upload" class="file-upload-label">
                Seleccionar Archivos ({{archivos.length}})
              </label>
              <label style="margin-left: 5px;" class="btn btn-danger" (click)="cancelarMaterial()">
                Cancelar
              </label>
              <input id="file-upload" style="cursor: pointer;" class="custom-file-input form-control" type="file"
                (change)="onFileSelected($event)" required multiple #archivoInput>
            </div>
            <div *ngIf="archivos.length === 0 && archivoInput.touched" class="text-danger">Archivo es requerido.
            </div>
          </div>
          <!-- Lista de archivos seleccionados -->
          <ul *ngIf="archivos.length > 0" class="mt-3 custom-scrollbar">
            <li *ngFor="let archivo of archivos; let i = index" class="mb-2 d-flex align-items-center">
              <div *ngIf="esImagen(archivo.file)">
                <img [src]="archivo.url" alt="{{ archivo.file.name }}"
                  style="max-width: 50px; max-height: 50px; border-radius: 5px; margin-right: 10px;">
              </div>
              <div *ngIf="!esImagen(archivo.file)">
                <i class="bx bx-file" style="font-size: 24px; margin-right: 10px;"></i>
              </div>
              <div>{{ archivo.file.name }}</div>
              <button type="button" class="btn btn-sm btn-danger ms-auto" (click)="eliminarArchivo(i)">
                <i class="bx bx-trash"></i>
              </button>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary waves-effect waves-light"
            [disabled]="materialForm.invalid || archivos.length === 0">
            Guardar material
          </button>
        </div>
      </form>
    </div>
  </div>

</ng-template>