import { Component, OnInit, ViewChild } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { Router, RouterModule } from "@angular/router";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { CursosService } from "src/app/service/cursos.service";
import { CommonModule } from "@angular/common";
import { Cursos, mapearCursosAlumnoInstructor, mapearCursosIniciados, mapearCursosProximos } from "src/app/interface/cursos";
import { FormsModule } from "@angular/forms";
import { FechasService } from "src/app/service/util/fechas.service";
import { ModalDetallesComponent } from "./modal-detalles/modal-detalles.component";
import { PaginacionComponent } from "../../common/paginacion/paginacion.component";
import { PaginatePipe } from "src/app/pipes/paginate.pipe";
import { ModalEvaluacionesComponent } from "./modal-evaluaciones/modal-evaluaciones.component";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { InicioService } from "src/app/service/inicio.service";
import { forkJoin } from "rxjs";
import * as moment from "moment";
import { ModalPromocionesComponent } from "../../common/modal-promociones/modal-promociones.component";

const opcionesFiltrado: { [key: string]: string[] } = {
  "Proximos Cursos": ["student", "admin"],
  "Mis cursos": ["student"],
  "Mi Historia": ["Instructor"],
  "Cursos Asignado": ["Instructor"],
  "Cursos Existentes": ["admin"],
  "Cursos Iniciados": ["admin"],
};

@Component({
  selector: "app-cursos-page",
  standalone: true,
  templateUrl: "./cursos-page.component.html",
  styleUrls: ["./cursos-page.component.scss"],
  imports: [ SidebarMenuComponent, CommonModule, FormsModule, RouterModule, ModalDetallesComponent,
    ModalEvaluacionesComponent, PaginacionComponent, PaginatePipe, ToastrModule, ModalPromocionesComponent
  ],
})
export class CursosPageComponent implements OnInit {
  private accionesFiltro: any;
  private valuePageSize: any;
  @ViewChild(ModalDetallesComponent)
  private modalDetallesComponente: ModalDetallesComponent;
  @ViewChild(ModalPromocionesComponent)
  private modalPromocionesComponente: ModalPromocionesComponent; // ViewChild para el componente del modal de promociones
  isToggled = false;
  rolUsuario: string;
  idAlumno: any;
  cursos: Cursos[] = [];
  cursoSeleccionado: Cursos;
  cursosIniciados: boolean = false;
  cursosProximos: boolean = false;
  opcionesFiltro: string[] = [];
  opcionSeleccionada: string;
  idCalendarioEvaluacion: number;
  encuestaPendiente: boolean = false;
  tipoEvaluacionSeleccionado: string;
  currentPage: number = 1;
  pageSize: number;
  ultimoTerminado: any;

  constructor(
    public themeService: ThemeCustomizerService,
    public dateService: FechasService,
    private router: Router,
    private toastrService: ToastrService,
    private cursosService: CursosService, private inicioService: InicioService
  ) {
    this.rolUsuario = localStorage.getItem("rolUsuario");
    this.idAlumno = localStorage.getItem("idAlumno");
    this.opcionesFiltro = this.obtenerOpcionesPorRol(this.rolUsuario);
    this.obtenerOpcionSeleccionadaPorRol();
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
    this.accionesFiltro = {
      "Proximos Cursos": () => this.obtenerProximosCurso(),
      "Mis cursos": () => this.obtenerCursos(),
      "Mi Historia": () => this.obtenerCursosHistorial(),
      "Cursos Asignado": () => this.obtenerCursos(),
      "Cursos Existentes": () => this.obtenerCursos(),
      "Cursos Iniciados": () => this.obtenerCursosIniciados(),
    };
    this.valuePageSize = {
      "Proximos Cursos": 10,
      "Mis cursos": 8,
      "Mi Historia": 8,
      "Cursos Asignado": 8,
      "Cursos Existentes": 10,
      "Cursos Iniciados": 8,
    };
    this.pageSize = this.valuePageSize[this.opcionSeleccionada];
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {
    this.obtenerCursos();
    // if(this.rolUsuario === 'student'){
    //   this.ultimoCursoTerminado()
    // }
  }

  private async obtenerCursos() {
    if (
      this.opcionSeleccionada === "Mis cursos" &&
      this.rolUsuario === "Instructor"
    ) {
      return;
    }
    this.cursos = await this.cursosService.obtenerCursos();
    const cursosEncuestas = this.cursos.filter(
      (c) => c.encuestaFinal || c.encuestaIntermedia
    );
    console.log(cursosEncuestas)
    if (cursosEncuestas && cursosEncuestas.length > 0) {
      this.encuestaPendiente = true;
    }
  }

  private async obtenerPromociones() {
  const response = await this.cursosService.obtenerPromociones()
  console.log(response, 'promos')
  if (this.modalPromocionesComponente) {
    this.modalPromocionesComponente.abrirModal(response);
  } else {
    console.error("modalPromocionesComponente no está inicializado");
  }
  }

  private async ultimoCursoTerminado() {
    forkJoin([
      this.inicioService.ultimoCursoTerminado(this.idAlumno)
    ]).subscribe(([ultimoTerminado]) => {
      this.ultimoTerminado = ultimoTerminado;
      console.log('ultimo', this.ultimoTerminado);

      // Calcula la diferencia de días entre la fecha de fin del último curso y la fecha actual
      const fechaFinCurso = moment(this.ultimoTerminado.fechaFin);
      const fechaActual = moment();
      const diferenciaDias = fechaActual.diff(fechaFinCurso, 'days');

      // Verifica si la diferencia de días es menor o igual a 10
      if (diferenciaDias <= 700 && localStorage.getItem("encuestaPendiente") === 'true') {
        this.obtenerPromociones();
      }
    });
  }

  private async obtenerProximosCurso() {
    this.cursosProximos = true;
    const response = await this.cursosService.cursosProximos();
    const data: any[] = [response];
    this.cursos = data[0].map((c: any) => mapearCursosProximos(c));
    if (this.rolUsuario === 'student' && this.cursosProximos) {
      this.ultimoCursoTerminado();
    }
  }

  private async obtenerCursosIniciados() {
    this.cursosIniciados = true;
    const response = await this.cursosService.cursosIniciados();
    const data: any[] = [response];
    this.cursos = data[0].map((c: any) => mapearCursosIniciados(c));
  }

  private async obtenerCursosHistorial() {
    const response = await this.cursosService.cursosHistorial();
    const data: any[] = [response];
    if(data[0].length > 0){
        const instructor:any = data[0];
        this.cursos = instructor[0].calendario.map((c: any) => mapearCursosAlumnoInstructor(c));
    }else{
        this.toastrService.warning("No hay historial por mostrar","Sin historial")
    }
  }

  redirigirDetalles(curso: Cursos) {
    if (this.cursosProximos) {
      this.cursoSeleccionado = curso;
      this.modalDetallesComponente.abrirModal();
    } else if (this.rolUsuario !== "admin" || this.cursosIniciados) {
        console.log("historial", curso.historial)
      this.router.navigate(["/curso", curso.idCalendario, curso.nombre]);
    } else {
      this.router.navigate([
        "/curso/material/",
        curso.idCurso,
        curso.nombre,
        curso.urlCurso,
      ]);
    }
  }

  mostrarEvaluaciones(tipoEvaluacion: string, idCalendario: any) {
    this.idCalendarioEvaluacion = idCalendario;
    if (tipoEvaluacion === "Intermedia") {
      this.tipoEvaluacionSeleccionado = "intermedia";
    } else {
      this.tipoEvaluacionSeleccionado = "final";
    }
  }

  realiarAccion(accion: number) {
    switch (accion) {
      case 1:
        this.tipoEvaluacionSeleccionado = null;
        this.idCalendarioEvaluacion = null;
        break;
      case 0:
        this.encuestaPendiente = false;
        this.toastrService.success("Encuesta realizada con exito.", "Exito");
        this.obtenerCursos();
        break;
    }
  }

  filtro(opcion: any) {
    if (this.encuestaPendiente) {
      this.obtenerOpcionSeleccionadaPorRol();
      return;
    }
    this.cursosIniciados = false;
    this.cursosProximos = false;
    if (this.accionesFiltro[opcion]) {
      this.cursos = [];
      this.currentPage = 1;
      this.pageSize = this.valuePageSize[opcion];
      this.accionesFiltro[opcion]();
    }
  }

  reducirTexto(
    texto: string,
    limit: number = 35,
    trail: string = "..."
  ): string {
    if (!texto) return "";
    if (texto.length <= limit) return texto;

    return texto.substring(0, 41 - trail.length) + trail;
  }

  private obtenerOpcionesPorRol(rol: string) {
    return Object.keys(opcionesFiltrado).filter((key) =>
      opcionesFiltrado[key].includes(rol)
    );
  }
  private obtenerOpcionSeleccionadaPorRol() {
    this.opcionSeleccionada = this.opcionesFiltro[1];
  }
  onPageChange(newPage: number) {
    this.currentPage = newPage;
  }
}
