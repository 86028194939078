<div class="col-lg-12 col-md-12 col-sm-12">
    <div class="pagination-area text-center">
        <a *ngFor="let page of getVisiblePages()" (click)="page !== '...' ? goToPage(page) : null" class="page-numbers"
            [class.current]="paginaActual === page" [class.disabled]="page === '...'">
            {{page}}
        </a>
        <a (click)="goToPage(paginaActual + 1)" class="page-numbers next"
            [class.disabled]="paginaActual === totalPaginas">
            <i class='bx bx-chevron-right'></i>
        </a>
    </div>
</div>
