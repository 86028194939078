import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FechasService {

  constructor() { }

  formatearFecha(fechaInicio: string, fechaFin: string): string {
    const format = 'dd/MM/yyyy';
    const locale = 'en-US';
    const formattedStartDate = formatDate(fechaInicio, format, locale);
    const formattedEndDate = formatDate(fechaFin, format, locale);
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
  formatearSoloFecha(fechaInicio: string): string {
    const format = 'dd/MM/yyyy';
    const locale = 'en-US';
    return formatDate(fechaInicio, format, locale);
  }
  formatearFechaConHora(dateTime: string): string {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  fechaActual(){
    return this.formatearSoloFecha(new Date().toISOString())
  }
}
