<ng-template #verDetalles role="document" let-modal>
    <div class="modal-content" [class.dark-modal]="themeService.isDark()">
        <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel" 
            [ngClass]="{'dark-modal-title': themeService.isDark()}">{{curso.nombre}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="cerrarModal()"></button>
        </div>
        <div class="modal-body" >
            <section class="why-choose-us-area-two ptb-custom" >
                <div class="container">
                    <div class="row align-items-center">
                        <h2 [ngClass]="{'dark-modal-title': themeService.isDark()}">{{curso.objetivo}}</h2>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-img">
                                <img id="imagenCurso" [src]="curso.banner" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12" [ngClass]="{'dark-modal-body': themeService.isDark()}">
                            <div class="why-choose-us-text">
                                <ul class="features-list">
                                    <li><span><i class='bx bx-user'></i>{{curso.instructor}}</span></li>
                                    <li><span><i class='bx bx-time'></i>{{curso.modalidad}}</span></li>
                                    <li><span><i class='bx bx-map'></i>{{curso.locacion}}</span></li>
                                    <li><span><i class='bx bx-calendar-week'></i>{{dateService.formatearSoloFecha(curso.fechaInicio)}}</span></li>
                                    <li><span><i class='bx' [ngClass]="{
                                        'bx-x-circle': curso.statusCalendario === 'Cancelado',
                                        'bx-check-circle': curso.statusCalendario === 'Confirmado',
                                        'bx-play-circle': curso.statusCalendario === 'Iniciado',
                                        'bx-minus-circle': curso.statusCalendario === 'No Aplica',
                                        'bx-calendar': curso.statusCalendario === 'Planeado',
                                        'bx-stop-circle': curso.statusCalendario === 'Terminado'
                                    }"></i>{{curso.statusCalendario}}</span></li>
                                </ul>
                                <a [href]="curso.urlTemario" target="_blank" class="default-btn">
                                    <i class='bx bx-user-circle icon-arrow before'></i><span class="label">
                                    Ver Temario
                                    </span><i class="bx bx-user-circle icon-arrow after"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</ng-template>
