import { Component, OnInit, ViewChild } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { DiplomaService } from "src/app/service/diploma.service";
import { FechasService } from "src/app/service/util/fechas.service";
import { CommonModule } from "@angular/common";
import { CursosService } from "src/app/service/cursos.service";
import { PaginacionComponent } from "../../common/paginacion/paginacion.component";
import { PaginatePipe } from "src/app/pipes/paginate.pipe";
import { ModalDiplomasCalendarioComponent } from "./modal-diplomas-calendario/modal-diplomas-calendario.component";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { SortingButtonsComponent } from "../../common/sorting-buttons/sorting-buttons.component";
import { SortService } from "src/app/service/util/sort.service";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-diplomas-page",
  standalone: true,
  templateUrl: "./diplomas-page.component.html",
  styleUrls: ["./diplomas-page.component.scss"],
  imports: [ HeaderStyleOneComponent, SidebarMenuComponent, CommonModule, FormsModule, PaginacionComponent,
    PaginatePipe, ModalDiplomasCalendarioComponent, ToastrModule, SortingButtonsComponent,
  ],
})
export class DiplomasPageComponent implements OnInit {
  private rolUsuario: string;
  private paginaApi: number = 1;
  private paginaParaHacerPeticion: number = 1;
  private cantidadPaginasDatos: number = 0;
  private totalDatos: number = 0;
  private sortOrder: "asc" | "desc" | null;
  private sortName: string = null;
  private busquedaRealizada: boolean = false;
  isToggled = false;
  diplomas: any[] = [];
  diplomasCalendario: any[] = null;
  nombreCursoFiltro: string = null;
  mostrarAdmin: boolean = false;
  currentPage: number = 1;
  currentPageItems: number = 1;
  pageSize: number = 10;
  totalRegistros: number = 0;
  isMobile: boolean;
  showAlert: boolean = false;

  constructor(
    public themeService: ThemeCustomizerService,
    private toastrService: ToastrService,
    public dateService: FechasService,
    private sortService: SortService,
    private diplomasService: DiplomaService,
    private cursosService: CursosService
  ) {
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
    this.rolUsuario = localStorage.getItem("rolUsuario");
    if (this.rolUsuario === "admin") {
      this.mostrarAdmin = true;
    }
    if(this.rolUsuario === 'Instructor'){
      this.showAlert = true
    }
    this.isMobile = window.innerWidth < 576;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 576;
      console.log('ismobile', this.isMobile)
    };
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleExpand(diploma: any) {
    diploma.isExpanded = !diploma.isExpanded;
  }

  ngOnInit(): void {
    if (this.rolUsuario === "student") {
      this.obtenerDiplomasAlumno();
    } else if (this.rolUsuario === "admin") {
      this.obtenerdiplomasAdministrador();
    }
  }

  private obtenerDiplomasAlumno() {
    this.diplomasService
      .diplomasAlumno(localStorage.getItem("idAlumno"))
      .then((diplomas) => {
        const data: any[] = [diplomas];
        this.diplomas = data[0];
      });
  }

  private obtenerdiplomasAdministrador() {
    this.cursosService
      .cursosTerminadosPorPagina(this.paginaApi)
      .then((cursosTerminados) => {
        this.diplomas = cursosTerminados[0]["hoja"];
        this.totalRegistros = cursosTerminados[0]["noRegistros"];
        if (this.totalDatos === 0) {
          this.totalDatos = this.diplomas.length;
          this.cantidadPaginasDatos = Math.ceil(
            this.totalDatos / this.pageSize
          );
          this.paginaParaHacerPeticion = this.cantidadPaginasDatos;
        }
        if(this.sortOrder != null && this.sortName != null){
            this.sortDiplomas(this.sortName,this.sortOrder)
        }
      });
  }

  obtenerDiplomasCalendario(idCalendario: number) {
    this.diplomasService.diplomasCalendario(idCalendario).then((diplomas) => {
      const data: any[] = [diplomas];
      this.diplomasCalendario = data[0];
      if (this.diplomasCalendario.length === 0) {
        this.toastrService.warning("No hay datos por mostrar.", "Data Vacia");
      }
    });
  }

  private obtenerCursoNombreFiltro(){
    if(this.nombreCursoFiltro != undefined && this.nombreCursoFiltro != null && this.nombreCursoFiltro.length > 0){
        this.cursosService.cursosTerminadosPorPaginaFiltro(this.paginaApi, this.nombreCursoFiltro)
        .then(cursosTerminados => {
          this.diplomas = cursosTerminados[0]["hoja"];
          this.totalRegistros = cursosTerminados[0]["noRegistros"];
          if (this.totalDatos === 0) {
            this.busquedaRealizada = true;
            this.totalDatos = this.diplomas.length;
            this.cantidadPaginasDatos = Math.ceil(
              this.totalDatos / this.pageSize
            );
            this.paginaParaHacerPeticion = this.cantidadPaginasDatos;
          }
          console.log("pagina api:",this.paginaApi)
          console.log("pagina par hacer peticion:",this.paginaParaHacerPeticion)
        })
        .catch(error => {
          this.toastrService.error("Error al traer el filtro de busqueda.", "Error Api");
        });
      }
  }

  nuevoFiltro(borrar: boolean){
    this.totalDatos = 0;
    this.paginaApi = 1;
    this.currentPage = 1;
    this.currentPageItems = 1;
    if(borrar){
        this.nombreCursoFiltro = null;
        this.busquedaRealizada = false;
        this.obtenerdiplomasAdministrador();
    }else{
        this.obtenerCursoNombreFiltro();
    }
  }

  sortDiplomas(prop: string, order: "asc" | "desc" | null) {
    if (order === null) {
      return;
    }
    this.sortName = prop;
    this.sortOrder = order;
    this.diplomas = this.sortService.sort(this.diplomas, prop, order);
    this.diplomas = [...this.diplomas];
  }

  onPageChange(newPage: number) {
    if (newPage > this.paginaParaHacerPeticion) {
      this.calculoCambiarPagina(newPage);
    }
    const paginasRecorridas = this.cantidadPaginasDatos * (this.paginaApi - 1);

    if (
      newPage < this.currentPage &&
      paginasRecorridas != 0 &&
      newPage <= paginasRecorridas
    ) {
      this.calculoCambiarPagina(newPage);
    }

    this.currentPage = newPage;
    this.currentPageItems = this.calculoPaginacionValores(newPage);
  }

  private calculoCambiarPagina(paginaActual: number) {
    this.paginaApi = Math.ceil(
      (paginaActual * this.pageSize) / this.totalDatos
    );
    const ultimaPagina = Math.ceil(this.totalRegistros / this.pageSize);
    const paginaHacerPeticion = this.cantidadPaginasDatos * this.paginaApi;

    this.paginaParaHacerPeticion =
      paginaHacerPeticion > ultimaPagina ? ultimaPagina : paginaHacerPeticion;

      if(this.busquedaRealizada){
        this.obtenerCursoNombreFiltro();
      }else{
        this.obtenerdiplomasAdministrador();
      }
  }

  private calculoPaginacionValores(paginaActual: number) {
    return paginaActual <= this.cantidadPaginasDatos
      ? paginaActual
      : Math.ceil(
          paginaActual - this.cantidadPaginasDatos * (this.paginaApi - 1)
        );
  }

  closeAlert() {
    this.showAlert = false;
}
}
