import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SortService } from 'src/app/service/util/sort.service';


@Component({
  selector: 'app-sorting-buttons',
  templateUrl: './sorting-buttons.component.html',
  styleUrls: ['./sorting-buttons.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SortingButtonsComponent {
  @Input() prop: string;
  @Input() isDark: boolean;
  @Output() sortEvent = new EventEmitter<{ prop: string, order: 'asc' | 'desc' | null }>();

  sortOrder: 'asc' | 'desc' | null = null;

  constructor(private sortService: SortService) {}

  toggleSortOrder(order: 'asc' | 'desc') {
    if (this.sortOrder === order) {
      this.sortOrder = null;
    } else {
      this.sortOrder = order;
    }
    this.emitSortEvent();
  }

  emitSortEvent() {
    this.sortEvent.emit({ prop: this.prop, order: this.sortOrder });
  }
}


