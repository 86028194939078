export const menuItemsAlumno: any[] = [{
    title: 'Inicio',
    isOpen: false,
    subItems: null,
    link:'/'
  }, {
    title: 'Cursos',
    isOpen: false,
    subItems:null,
    link:'/cursos'
  }, {
    title: 'Biblioteca',
    isOpen: false,
    subItems: null,
    link:'/biblioteca'
  }, {
    title: 'Diplomas',
    isOpen: false,
    subItems: null,
    link:'/diplomas'
  }, {
    title: 'Examenes',
    isOpen: false,
    subItems: null
  },
  {
    title: 'Dudas o comentarios',
    isOpen: false,
    subItems: [
      {label: 'contacto@tecgurus.net', link : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas" },
      { label: '+525562304805', link: 'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.' }
    ]
  }, {
    title: 'Conoce ofertas de empleo',
    isOpen: false,
    subItems: null,
  }];

export const menuItemsInstructor: any[] = [{
    title: 'Inicio',
    isOpen: false,
    subItems: null,
    link:'/'
  }, {
    title: 'Cursos',
    isOpen: false,
    subItems:null,
    link:'/cursos'
  }, {
    title: 'Biblioteca',
    isOpen: false,
    subItems: null,
    link:'/biblioteca'
  }, {
    title: 'Diplomas',
    isOpen: false,
    subItems: null,
    link:'/diplomas'
  }, {
    title: 'Examenes',
    isOpen: false,
    subItems: null
  },
  {
    title: 'Evaluaciones',
    isOpen: false,
    subItems: null,
    link:'/evaluaciones'
  },
  {
    title: 'Dudas o comentarios',
    isOpen: false,
    subItems: [
      {label: 'contacto@tecgurus.net', link : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas" },
      { label: '+525562304805', link: 'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.' }
    ]
  }];

export const menuItemsAdmin: any[] = [{
    title: 'Inicio',
    isOpen: false,
    subItems: null,
    link:'/'
  }, {
    title: 'Usuarios',
    isOpen: false,
    subItems:null,
    link:'/usuarios'
  }, {
    title: 'Cursos',
    isOpen: false,
    subItems:null,
    link:'/cursos'
  }, {
    title: 'Biblioteca',
    isOpen: false,
    subItems: null,
    link:'/biblioteca'
  }, {
    title: 'Diplomas',
    isOpen: false,
    subItems: null,
    link:'/diplomas'
  }, {
    title: 'Evaluaciones',
    isOpen: false,
    subItems:null,
    link:'/evaluaciones'
  }, {
    title: 'Examenes',
    isOpen: false,
    subItems: null
  },
  {
    title: 'Dudas o comentarios',
    isOpen: false,
    subItems: [
      {label: 'contacto@tecgurus.net', link : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas" },
      { label: '+525562304805', link: 'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.' }
    ]
  }, {
    title: 'Conoce ofertas de empleo',
    isOpen: false,
    subItems: null
  }];

  export interface MenuItem {
    title?: string;
    isOpen?: boolean;
    subItems?: { label: string; link: string }[] | null;
    link?: string;
  }
  
