<app-sidebar-menu></app-sidebar-menu>

<div
    class="my-dashboard-area" style="padding-top: 20px;"
    [class.dark-my-dashboard-area]="themeService.isDark()"
>
    <div class="container">
        <div class="myDashboard-profile">
        </div>
        <div class="myDashboard-content">


            <h3>USUARIOS</h3>
            <div class="recent-orders-table table-responsive">
                <table id="basic-datatable" class="table table-sm table-bordered align-middle nowrap">
                  <thead>
                    <tr>
                      <th>
                        <div>Nombre
                          <button class="button-arrow" (click)="sortUsuarios('name', 'asc')"
                            [ngClass]="{'text-light': themeService.isDark()}">
                            <i class="bx bx-up-arrow-circle"></i>
                          </button>
                          <button class="button-arrow" (click)="sortUsuarios('name', 'desc')"
                            [ngClass]="{'text-light': themeService.isDark()}">
                            <i class="bx bx-down-arrow-circle"></i>
                          </button>
                        </div>
                        <input type="text" class="filter-input" (keyup)="filterUsuarios($event, 'name')" placeholder="Filtrar por Nombre">
                      </th>
                      <th>
                        <div>Email
                          <button class="button-arrow" (click)="sortUsuarios('email', 'asc')"
                            [ngClass]="{'text-light': themeService.isDark()}">
                            <i class="bx bx-up-arrow-circle"></i>
                          </button>
                          <button class="button-arrow" (click)="sortUsuarios('email', 'desc')"
                            [ngClass]="{'text-light': themeService.isDark()}">
                            <i class="bx bx-down-arrow-circle"></i>
                          </button>
                        </div>
                        <input type="text" class="filter-input" (keyup)="filterUsuarios($event, 'email')" placeholder="Filtrar por email">
                      </th>
                      <th>
                        <div>Tipo de usuario
                          <button class="button-arrow" (click)="sortUsuarios('role', 'asc')"
                            [ngClass]="{'text-light': themeService.isDark()}">
                            <i class="bx bx-up-arrow-circle"></i>
                          </button>
                          <button class="button-arrow" (click)="sortUsuarios('role', 'desc')"
                            [ngClass]="{'text-light': themeService.isDark()}">
                            <i class="bx bx-down-arrow-circle"></i>
                          </button>
                        </div>
                        <input type="text" class="filter-input" (keyup)="filterUsuarios($event, 'role')" placeholder="Filtrar por tipo">
                      </th>
                      <th>Detalles</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of usuarios | paginate : currentPage : pageSize">
                        <td style="width:300px;">{{ item.name }}</td>
                        <td style="width:300px;">{{ item.email }}</td>
                        <td style="width:300px;">{{ item.role }}</td>
                        <td class="text-center"><button pButton type="button" class="btn btn-primary btn-ver"  (click)="abrirModalDetalles(detallesUsuario, item)" > <i class="bx bx-show"></i>
                          Ver</button></td>

                    </tr>

                  </tbody>
                </table>
                <div *ngIf="isMobile">
                  <input type="text" class="filter-input" (keyup)="filterUsuarios($event, 'name')" placeholder="Filtrar por nombre">
                  <div class="accordion" *ngFor="let item of usuarios | paginate : currentPage : pageSize">
                    <div class="accordion-header">
                        <span>{{item.name}} </span>
                        <button class="btn btn-sm btn-secondary" (click)="seleccionarItem(item.email)">
                            <i [class]="(item.email === correoSeleccionado ? 'bx bx-chevron-up':'bx bx-chevron-down')"></i>
                        </button>
                    </div>
                    <div class="accordion-content" [ngClass]="{'expand': item.email === correoSeleccionado}">
                        <div class="column-one">
                            <strong>Email:</strong><br>
                            <strong>Tipo de usuario:</strong><br>
                        </div>
                        <div class="column-two">
                            <span>{{item.email}}</span><br>
                            <span>{{item.role}}</span><br>
                            <span><button pButton type="button" class="btn btn-primary"  (click)="abrirModalDetalles(detallesUsuario, item)" > <i class="bx bx-show"></i></button></span>
                        </div>
                    </div>
                </div>
                </div>
                <app-paginacion [paginaActual]="currentPage"
                [elementosPorPagina]="pageSize"
                [totalElemetos]="usuarios.length"
                (cambioPagina)="onPageChange($event)">
            </app-paginacion>
              </div>
        </div>
    </div>
</div>




<!-- MODAL DETALLES USUARIOS -->

<ng-template #detallesUsuario role="document" let-modal >
<div class="modal-content" [class.dark-modal]="themeService.isDark()">
  <div class="modal-header" style="align-items:flex-start;;">
    <div class="header-order">
       <!-- <img  src="assets/img/usuarios/{{rolUsuarioModal}}.png"  style="width: 150px; display: block; margin: 0 auto;"> -->
    <img  src="assets/img/roles/{{rolUsuarioModal}}.png"  style="width: 20%; display: block;">
      <h5 class="modal-title" [class.dark-title]="themeService.isDark()" id="myModalLabel">Detalles del Usuario</h5>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modalRef?.hide()"></button>
  </div>
  <div >
    <form [formGroup]="formAbrirDetalles" style="display:flex; flex-direction:column; padding: 1rem;">
      <div class="item-modal">
        <strong class="modal-label">Id: </strong>
        <input class="modal-input" readonly formControlName="id" type="text">
      </div>
      <div class="item-modal">
        <strong class="modal-label">Nombre: </strong>
          <input class="modal-input" readonly formControlName="nombre" type="text">
      </div>
      <div class="item-modal">
        <strong class="modal-label">Email: </strong>
          <input class="modal-input" readonly formControlName="email" type="text">
      </div>
      <div class="item-modal">
        <strong class="modal-label">Role: </strong>
        <input class="modal-input" readonly formControlName="role" type="text">
      </div>
    </form>
  </div>
</div>
</ng-template>
