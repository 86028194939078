import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbsService } from 'src/app/service/util/breadcrumbs.service';
import { MenuItem } from '../sidebar-menu/menu-items';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls:['./breadcrumbs.component.scss'],
    standalone: true,
    imports: [RouterModule, CommonModule]
})
export class AppBreadcrumbsComponent implements OnDestroy, OnInit {
  subscription: Subscription;
  items: MenuItem[];

  constructor(public breadcrumbService: BreadcrumbsService) {}

  ngOnInit(): void {
    // Subscribe to breadcrumb items from the service
    this.subscription = this.breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Unsubscribe to prevent memory leaks
    }
  }
}
