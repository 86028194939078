import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { BibliotecaService } from 'src/app/service/biblioteca.service';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FechasService } from 'src/app/service/util/fechas.service';

@Component({
    selector: 'app-events-details-page',
    standalone: true,
    templateUrl: './biblioteca-detalles.component.html',
    styleUrls: ['./biblioteca-detalles.component.scss'],
    imports: [ RouterLink, HeaderStyleOneComponent, SidebarMenuComponent,
        CommonModule, FormsModule, NgbModule
    ],
    providers: [DatePipe]
})
export class BibliotecaDetallesComponent implements OnInit {

    isToggled = false;
    loading: boolean = true;
    openAccordionIndex: number = -1;
    tipoUsuario: string = localStorage.getItem("rolUsuario");
    safeUrl: SafeResourceUrl;
    idBiblioteca: string;
    urlBiblioteca: string;
    detallesBiblioteca: any = {};
    temarios: any;
    subtemaSeleccionado: any = [];
    urlRecurso: string = "s/r";
    urlArchivosBiblioteca: string = environment.urlArchivosBiblioteca;

    bloquearAcceso: boolean = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private bibliotecaService: BibliotecaService,
        public themeService: ThemeCustomizerService,
        private sanitizer: DomSanitizer, private fechasService: FechasService
    ) {
        this.activatedRoute.params.subscribe(params => {
            this.idBiblioteca = params['idRepositorio'];
            this.urlBiblioteca = params['url'];
          });

        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        this.obtenerTemarios();
        this.obtenerDetallesBiblioteca();
    }

    obtenerDetallesBiblioteca() {
        this.bibliotecaService.busquedaById(this.idBiblioteca).subscribe(detallesBiblioteca => {
            this.detallesBiblioteca = detallesBiblioteca;
            console.log('detalles', detallesBiblioteca)
        }, error => {
            console.log(error);
        });
    }

    obtenerTemarios() {
        this.bibliotecaService.temarioBiblioteca(this.idBiblioteca).subscribe(temarios => {
            this.temarios = temarios["temario"];

            // Ordenar los temarios por el número inicial en nombreTema
            this.temarios.sort((a: any, b: any) => {
                const numeroA = parseInt(a.nombreTema.split('.')[0], 10);
                const numeroB = parseInt(b.nombreTema.split('.')[0], 10);
                return numeroA - numeroB;
            });

            this.bloquearAcceso = !temarios['permitirAcceso'];
            console.log(this.temarios);
            if (temarios['permitirAcceso'] === true) {
                if (temarios["ultimoVisto"] != null) {
                    this.subtemaSeleccionado = temarios["ultimoVisto"];
                } else {
                    this.subtemaSeleccionado = this.temarios[0]["subtemas"][0];
                    if (this.temarios[0]["subtemas"][0]["visto"] == false) {
                        this.registrarVistaSubtema(this.temarios[0]["subtemas"][0]["idTemaSubtema"]);
                        this.subtemaSeleccionado["visto"] = true;
                    }
                }
                this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.subtemaSeleccionado.urlVideo);
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log(error)
        });
    }

    cargarRecursoTemario(subtema: any) {
        this.registrarVistaSubtema(subtema["idTemaSubtema"]);
        subtema["visto"] = true;
        this.subtemaSeleccionado = subtema;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.subtemaSeleccionado.urlVideo);
        window.scrollTo(0, 100);
    }

    registrarVistaSubtema(idTemaSubtema: string) {
        const subtemaARegistrar = JSON.stringify({ "temasubtema": { "idTemaSubtema": idTemaSubtema } });
        this.bibliotecaService.registrarVistaSubtema(subtemaARegistrar).subscribe(subtema => {
            // Manejar respuesta si es necesario
        }, error => {
            console.log(error)
        });
    }

    formatearFechaConHora(fechaHora: string): string {
        return this.fechasService.formatearFechaConHora(fechaHora)
    }

    toggleAccordion(index: number) {
        if (this.openAccordionIndex === index) {
            this.openAccordionIndex = -1;
        } else {
            this.openAccordionIndex = index;
        }
    }

}
