import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  url:string;
  
  constructor(private http:HttpClient) {
    this.url = environment.baseApi;
  }

  todos(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    console.log(localStorage.getItem("token"))
    return this.http.get(this.url+ "users/all/",{headers});
  }

  perfilCompleto(idUsuario:number){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "users/datos-generales/"+idUsuario,{headers});
  }

  actualizarDatosPersonales(datosUsuario:FormData){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.put(this.url+ "users/actualizar-datos-generales",datosUsuario,{headers});
  }
}
