import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  private urlApi: string = `${environment.baseApi}`;
  
  constructor(private http: HttpClient) { }

  obtenerUltimasNotificaciones() {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get<any>(this.urlApi+ "notificaciones/user/5",{headers});
  }

  verNotificacion(jsonData:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(this.urlApi+ "notificaciones/ver/",jsonData,{headers});
  }
}
