
<app-sidebar-menu></app-sidebar-menu>

<div
    class="my-dashboard-area ptb-100"
    [class.dark-my-dashboard-area]="themeService.isDark()"
>
    <div class="container">
        <h3>Perfil</h3>
        <div class="myDashboard-content">
            
            <form class="edit-account">
                <div class="row">
                    <div class="subtitulo-img">
                        <h3>Datos de sesion</h3>
                        <div class="p-col-12 p-md-6 p-lg-4 texto">{{rolUsuario}} <img src="assets/img/roles/{{rolUsuario}}.png" style="width: 100%; max-width: 50px;"/> </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Nombre de usuario</label>
                            <input  type="text" class="form-control" value="{{nombreUsuario}}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Correo</label>
                            <input  type="text" class="form-control" value="{{email}}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Tipo de cuenta</label>
                            <input  type="text" class="form-control" value="{{rolUsuario}}">
                        </div>
                    </div>
                    <h3>Datos personales</h3>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input  type="text" class="form-control" [(ngModel)]="usuario.name"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Telefono</label>
                            <input  (keydown)="validarNumeros($event)"  type="text" class="form-control" [(ngModel)]="usuario.phone"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Domicilio</label>
                            <input  type="text" class="form-control" [(ngModel)]="usuario.address"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"
                        (click)="actualizarInformacion()"><i class='bx bx-save icon-arrow before'></i><span class="label">Actualizar informacion</span><i class="bx bx-save icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>