<app-sidebar-menu></app-sidebar-menu>

<div
    class="courses-area "
    [class.dark-courses-area]="themeService.isDark()"
>
    <div class="container">
      <div class="courses-topbar">
        <div class="contenedor-header">
          <div class="col-lg-4">
            <div class="topbar-result-count" *ngIf="rolUsuario !== 'student'">
              <p>Mostrando del {{ getCurrentRangeStart() }} al
                {{ getCurrentRangeEnd() }} de {{ filteredCursosImpartidos.length > 0? filteredCursosImpartidos.length :
                cursosImpartidos.length }} Cursos</p>
            </div>
            <div class="topbar-result-count" *ngIf="rolUsuario === 'student'"
              style="display: inline-flex; margin-top: 20px;">
              <p>
                Mostrando ultimo curso Inscrito
                <span *ngIf="ultimoCursoTerminado !== null"> y ultimo curso Terminado</span>
              </p>
            </div>
            <div class="topbar-result-count" *ngIf="rolUsuario === 'student' && ultimoCursoInscrito === null"
              style="display: inline-flex; margin-top: 20px;">
              <p *ngIf="ultimoCursoTerminado !== null">Mostrando ultimo curso Terminado</p>
            </div>
          </div>
            <div *ngIf="rolUsuario !== 'student'" class="topbar-ordering-and-search">
                  <div class="topbar-search">
                    <form>
                      <label><i class="bx bx-search"></i></label>
                      <input type="text" class="input-search" placeholder="Buscar aqui..." [(ngModel)]="searchText"
                        name="search" (keyup)="onSearchTextChange($event)">
                    </form>
                  </div>
            </div>
        </div>
      </div>
        <div class="row cursos-order">
            <div class="col-lg-4 col-md-6 curso-contenedor" *ngFor="let item of rolUsuario === 'student' ? cursosParaMostrar : paginatedCursosImpartidos">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-image">
                        <a  class="d-block"><img src="{{rolUsuario !== 'student'? item.urlBanner : item.urlBarner}}" alt="image" ></a>
                        <div class="courses-tag">
                            <a class="d-block">{{ formatearFecha(item.fechaInicio, item.fechaFin) }}</a>
                        </div>
                    </div>
                    <div class="courses-content" [ngStyle]="{
                        'padding': rolUsuario === 'Instructor' || rolUsuario === 'student'? '25px 0 0' : '25px 0 18px'
                    }">
                    
                        <div class="course-author d-flex align-items-center">
                            <img src="{{rolUsuario !== 'student'? item.urlBanner : item.urlBarner}}" class="rounded-circle mr-2" alt="image" *ngIf="rolUsuario !== 'student'">
                            <span>{{item.instructor}}</span>
                        </div>
                        <h3><a  class="d-inline-block courses-title">{{item.curso}}</a></h3>
                        <div class="courses-rating" *ngIf="rolUsuario === 'admin'">
                            <div class="rating-category" >
                              <div>Encuesta Intermedia:</div>
                              <div class="star-rating">
                                <i *ngFor="let star of [].constructor(getEncuestaValues(item.encuestaIntermedia).total); let i = index"
                                   class="bx"
                                   [ngClass]="{'bxs-star': i < getEncuestaValues(item.encuestaIntermedia).completed, 'bx-star': i >= getEncuestaValues(item.encuestaIntermedia).completed}">
                                </i>
                              </div>
                              {{item.encuestaIntermedia}}
                            </div>
                            <div class="rating-category">
                              <div>Encuesta Final:</div>
                              <div class="star-rating">
                                <i *ngFor="let star of [].constructor(getEncuestaValues(item.encuestaFinal).total); let i = index"
                                   class="bx"
                                   [ngClass]="{'bxs-star': i < getEncuestaValues(item.encuestaFinal).completed, 'bx-star': i >= getEncuestaValues(item.encuestaFinal).completed}">
                                </i>
                                {{item.encuestaFinal}}
                              </div>
                            </div>
                          </div>
                    </div>
                    <div class="courses-box-footer" *ngIf="rolUsuario === 'admin'">
                        <ul style="display: inline;">
                          <li class="course-location">
                            <i class='bx bx-map'></i> {{item.locacion}}
                          </li>
                          <li class="course-modality">
                            <i class='bx bx-time-five'></i> {{item.modalidad}}
                          </li>
                          <li class="course-attendance">
                            
                            <i class='bx bx-calendar-check'></i>Asistencias al dia: {{item.asistencias === true? 'SI': 'NO'}}
                          </li>
                          <li class="course-material">
                            
                            <i class='bx bx-folder'></i>Material compartido: {{item.noMaterialCompartido}}
                          </li>
                        </ul>
                      </div>
                      <div class="courses-box-footer" *ngIf="rolUsuario === 'Instructor'">
                        <ul style="display: inline;">
                            <li class="course-modality">
                                <i class='bx bx-time-five'></i> {{ item.modalidad + ' - ' + item.horaInicio }}
                            </li>
                            <li class="course-status">
                                <i class='bx' [ngClass]="{
                                    'bx-x-circle': item.statusCalendario === 'Cancelado',
                                    'bx-check-circle': item.statusCalendario === 'Confirmado',
                                    'bx-play-circle': item.statusCalendario === 'Iniciado',
                                    'bx-minus-circle': item.statusCalendario === 'No aplica',
                                    'bx-calendar': item.statusCalendario === 'Planeado',
                                    'bx-stop-circle': item.statusCalendario === 'Terminado'
                                }"></i> {{ item.statusCalendario }}
                            </li>
                          <li class="course-attendance">
                            <i class='bx bx-calendar-check'></i>Asistencias al dia: {{item.asistencias === true? 'SI': 'NO'}}
                          </li>
                          <li class="students-number">
                            <i class='bx bx-user'></i>Num. Alumnos {{item.noInscripciones}}
                        </li>
                          <li class="course-material">
                            <i class='bx bx-folder'></i>Material compartido: {{item.noMaterialCompartido}}
                          </li>
                        </ul>
                      </div>
                      <div class="courses-box-footer" *ngIf="rolUsuario === 'student'">
                        <ul style="display: inline;">
                            <li class="course-modality">
                                <i class='bx bx-time-five'></i> {{ item.modalidad + ' - ' + item.horaInicio }}
                            </li>
                            <li class="course-status">
                                <i class='bx' [ngClass]="{
                                    'bx-x-circle': item.status === 'Cancelado',
                                    'bx-check-circle': item.status === 'Confirmado',
                                    'bx-play-circle': item.status === 'Iniciado',
                                    'bx-minus-circle': item.status === 'No aplica',
                                    'bx-calendar': item.status === 'Planeado',
                                    'bx-stop-circle': item.status === 'Terminado'
                                }"></i> {{ item.status }}
                            </li>
                        </ul>
                      </div>
                      <div class="footer-buttons">
                        <button class="btn btn-sm btn-success order-button-icon" 
                        *ngIf="rolUsuario !== 'student'" (click)="abrirModalVerPublicaciones(verPublicaciones,
                         item.idCurso, item.curso, item.idCalendario, item.publicaciones)">
                          <i class='bx bx-show'></i> Ver publicaciones
                        </button>
                        <button class="btn btn-sm btn-primary order-button-icon" [routerLink]="['/curso', item.idCalendario, item.url]"
                        *ngIf="rolUsuario === 'admin' || rolUsuario === 'Instructor' || (rolUsuario === 'student' && item.status !== 'Terminado')">
                          <i class='bx bx-right-arrow'></i> Ir al curso
                        </button>
                        <a *ngIf="ultimoCursoTerminado" href="{{ultimoCursoTerminado.urlDiploma}}" target="_blank">
                          <button class="btn btn-sm btn-success order-button-icon"
                        *ngIf="item.status === 'Terminado'">
                       
                        <i class='bx bx-medal'></i> Ver diploma
                        </button>
                        </a>
                        
                      </div>
           
            </div>
           
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="rolUsuario !== 'student' && cursosImpartidos.length > 0">
      <div class="pagination-area text-center">
        <a *ngFor="let page of getVisiblePages(); let i = index"
            (click)="page !== '...' ? goToPage(page) : null"
            class="page-numbers"
            [class.current]="currentPage === page"
            [class.disabled]="page === '...'">
            {{page}}
        </a>
        <a (click)="goToPage(currentPage + 1)"
            class="page-numbers next"
            [class.disabled]="currentPage === getTotalPages()">
            <i class='bx bx-chevron-right'></i>
        </a>
    </div>
</div>
</div>

<!-- MODAL VER PUBLICACIONES -->
<ng-template #verPublicaciones role="document" let-modal>
  <div [ngClass]="{'dark-modal': themeService.isDark()}">
    <div class="modal-header">
      <h5 class="modal-title" [ngClass]="{'dark-modal-title': themeService.isDark()}">Publicaciones del curso: {{nombreCurso}}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cerrarModal()"></button>
    </div>
    <div class="modal-body" [ngClass]="{'dark-modal-body': themeService.isDark()}">
      <table id="basic-datatable" class="table table-bordered">
        <thead [ngClass]="{'dark-table': themeService.isDark()}">
          <tr>
            <th>Nombre</th>
            <th>Comentarios de alumnos</th>
            <th>Comentarios del instructor</th>
          </tr>
        </thead>
        <tbody [ngClass]="{'dark-table': themeService.isDark()}">
          <tr *ngFor="let publicacion of publicacionesCurso | paginate : currentPageTable : pageSize">
            <td>{{publicacion.nombre}}</td>
            <td>{{publicacion.noComentarios}}</td>
            <td>{{publicacion.noComentariosInstuctor}}</td>
          </tr>
        </tbody>
      </table>
      <app-paginacion [paginaActual]="currentPageTable"
                      [elementosPorPagina]="pageSize"
                      [totalElemetos]="publicacionesCurso.length"
                      (cambioPagina)="onPageChange($event)">
      </app-paginacion>
    </div>
  </div>
</ng-template>

