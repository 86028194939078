import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { IniciarSesionService } from '../service/iniciar-sesion.service';


export const autenticacionGuard: CanActivateFn = (route, state) => {
    const iniciarSessionService = inject(IniciarSesionService);
    const router = inject(Router);
    console.log("entro en guard autenticacion")

    if(localStorage.getItem("token") ){
        var fechaFin = new Date( localStorage.getItem("expiracionToken") ).getTime();
        var fechaActual = new Date().getTime();
        if(fechaActual < fechaFin){
          return true;
        }else{
          iniciarSessionService.logout();
          router.navigateByUrl('/iniciar-sesion');
        };
    }else{
        router.navigateByUrl('/iniciar-sesion');
        return false;
    }

}
