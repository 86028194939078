import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { AsistenciaService } from "src/app/service/asistencia.service";
import { CommonModule } from "@angular/common";
import { FechasService } from "src/app/service/util/fechas.service";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-asistencia-page",
  standalone: true,
  templateUrl: "./asistencia-page.component.html",
  styleUrls: ["./asistencia-page.component.scss"],
  imports: [
    HeaderStyleOneComponent,
    RouterLink,
    SidebarMenuComponent,
    CommonModule,
    FormsModule,
    ToastrModule,
  ],
})
export class AsistenciaPageComponent implements OnInit {
  private idCalendario: number;
  private datosAsistencias: any[];
  private email: string;
  private mapFechasDesahiblitar: Map<string, boolean> = new Map();
  asistenciaUsuarios: any[] = [];
  datosPersonales: any[] = [];
  asistenciasFecha: any[] = [];
  isToggled = false;
  rolUsuario: string;
  idInscripcionSeleccionado: any = null;
  correoSeleccionado: string = "";

  constructor(
    public themeService: ThemeCustomizerService,
    public dateService: FechasService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private asistenciasService: AsistenciaService
  ) {
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
    this.activatedRoute.params.subscribe((params) => {
      this.idCalendario = params.idcalendario;
    });
    this.rolUsuario = localStorage.getItem("rolUsuario");
    this.email = localStorage.getItem("email");
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {
    this.obtenerAsistencias();
  }

  private async obtenerAsistencias() {
    const response = await this.asistenciasService.obtenerAsistencia(
      this.idCalendario
    );
    const data: any[] = [response];
    this.datosAsistencias = data[0];
    for (let i = 0; i < this.datosAsistencias[0].asistencias.length; i++) {
      this.asistenciaUsuarios.push(this.datosAsistencias[0].asistencias[i]);
    }
    for (let i = 0; i < this.datosAsistencias.length; i++) {
      this.asistenciasFecha.push({
        fecha: this.datosAsistencias[i].fechaAsistencia,
        loading: false,
      });
    }
    if (this.rolUsuario == "student") {
      let indiceAlumno = this.datosAsistencias[0].asistencias.findIndex(
        (dato: any) => dato.email == this.email
      );
      this.datosPersonales.push(
        this.datosAsistencias[0].asistencias[indiceAlumno]
      );
    } else {
      this.asistenciasFecha = this.asistenciasFecha.map((fechaObjeto) => {
        const asistenciaObj = this.datosAsistencias.find(
          (a) => a.fechaAsistencia === fechaObjeto.fecha
        );

        if (asistenciaObj) {
          const asistenciaMap = asistenciaObj.asistencias.reduce(
            (acc: any, curr: any) => {
              acc[curr.idInscripcion] = curr.asistencia;
              return acc;
            },
            {}
          );

          return {
            ...fechaObjeto,
            ...asistenciaMap,
          };
        } else {
          return fechaObjeto;
        }
      });
    }
  }

  guardarAsistencia(fecha: string, fechaObjeto: any, idInscripcion: string) {
    this.idInscripcionSeleccionado = idInscripcion;
    fechaObjeto.loading = true;
    const asistencia: boolean = fechaObjeto[idInscripcion];
    const jsonData = {
      fechaAsistencia: fecha,
      idCalendario: this.idCalendario,
      asistencia: { idInscripcion: idInscripcion, asistencia: asistencia },
    };
    this.asistenciasService.registrarAsistencia(jsonData).subscribe(
      (data: any) => {
        fechaObjeto[idInscripcion] = data.asistencia;
        fechaObjeto.loading = false;
        this.idInscripcionSeleccionado = null;
      },
      (error) => {
        const titulo = `Error ${error.status} al guardar las asistencias.`;
        const mensaje = `Detalles: ${error.name}`;
        this.idInscripcionSeleccionado = null;
        fechaObjeto.loading = false;
        this.toastrService.error(mensaje, titulo);
      }
    );
  }

  obtenerAsistenciaEstudiante(fecha: string, email: string) {
    const datoAsistencia: any = this.datosAsistencias.filter(
      (a) => a.fechaAsistencia === fecha
    );
    const alumno: any = datoAsistencia[0].asistencias.filter(
      (a) => a.email === email
    )[0];
    console.log(alumno);
    return alumno.asistencia;
  }

  obtenerAsistenciaAdminInstructor(
    fecha: string,
    email: string,
    validarModificacion: boolean = false
  ) {
    const datoAsistencia: any = this.datosAsistencias.filter(
      (a) => a.fechaAsistencia === fecha
    );
    if (validarModificacion) {
      this.mapFechasDesahiblitar.set(
        fecha,
        datoAsistencia[0].permitirModificacion
      );
      return datoAsistencia[0].permitirModificacion;
    }
    const alumno: any = datoAsistencia[0].asistencias.filter(
      (a) => a.email === email
    )[0];
    return alumno.asistencia;
  }

  seleccionarCorreo(email: string) {
    this.correoSeleccionado =
      this.correoSeleccionado === ""
        ? email
        : this.correoSeleccionado === email
        ? ""
        : email;
  }

  get anchoColumna() {
    return `${this.classCustom}-tabla`;
  }
  get classCustom() {
    return this.asistenciasFecha.length > 10
      ? "compacto"
      : this.asistenciasFecha.length <= 6
      ? "small"
      : "normal";
  }
  deshabilitarFecha(fecha: string): boolean {
    return this.mapFechasDesahiblitar.get(fecha);
  }
}
