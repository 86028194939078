import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AsistenciaService {
  private urlApi: string = `${environment.baseApi}asistencias`;

  constructor(private http: HttpClient) {}

  obtenerAsistencia(idCalendario: number) {
    return this.http
      .get(`${this.urlApi}/calendario/${idCalendario}`, {
        headers: this.obtenerHeaders(),
      })
      .toPromise();
  }
  registrarAsistencia(jsonData: any) {
    const headers = this.obtenerHeaders();
    return this.http.post(`${this.urlApi}/registrar`, jsonData, {headers});
  }

  private obtenerHeaders() {
    return new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    });
  }
}
