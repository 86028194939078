export interface Material {
    nombre: string;
    descripcion: string;
    tipoMaterial: string;
    fechaHora: string;
    archivo: string;
    icono: string;
}

export const mapearResponseMaterial = (data: any):Material => {
    console.log(data);
    return {
        descripcion: data.descripcion,
        fechaHora: data.fechaHora,
        nombre: data.nombre,
        tipoMaterial: data.tipoMaterial,
        archivo: data.archivo,
        icono: null
    }
}
