import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filter<T>(data: T[], searchText: string, field: string): T[] {
    if (!searchText) {
      return data;
    }

    const searchTextLower = searchText.toLowerCase();

    return data.filter(item => {
      let valueToFilter = item;
      field.split('.').forEach(key => {
        valueToFilter = valueToFilter[key];
      });

      // Verifica si valueToFilter es una cadena antes de llamar a toLowerCase
      if (typeof valueToFilter === 'string') {
        return valueToFilter.toLowerCase().includes(searchTextLower);
      } else {
        return false;
      }
    });
  }
}

