import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../theme-customizer/theme-customizer.service';

@Component({
  selector: 'app-modal-promociones',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-promociones.component.html',
  styleUrl: './modal-promociones.component.scss'
})
export class ModalPromocionesComponent implements OnInit{
  isVisible: boolean = false;
  datosPromociones: any; // Para almacenar los datos de promociones
  promocionesArray: any[] = []
  nombreCurso: string;
  fechaInicio: string;
  isToggled = false

  constructor(private cdr: ChangeDetectorRef, public themeService: ThemeCustomizerService) { 
    this.themeService.isToggled$.subscribe(isToggled => {
    this.isToggled = isToggled;
});
}

toggleTheme() {
  this.themeService.toggleTheme();
}

  ngOnInit(): void {}

  abrirModal(datos: any): void {
    this.datosPromociones = datos[0];
    this.promocionesArray = datos
    this.isVisible = true;
    this.cdr.detectChanges(); // Add this line
    setTimeout(() => {
       this.cerrarModal();
    }, 60000); // Cerrar el modal automáticamente después de 1 minuto
  }

  cerrarModal(): void {
    this.isVisible = false;
  }

  redirigirAWhatsapp(item: any) {
    const fechaInicio = new Date(item.fechaInicio).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    const mensaje = encodeURIComponent(`Vengo de la página, deseo inscribirme a ${item.nombreCurso} con 50% de descuento del ${fechaInicio}`);
    const telefono = '5215561868835'; 
    window.open(`https://api.whatsapp.com/send?phone=${telefono}&text=${mensaje}`, '_blank');
}


}
