<ng-template #verDiplomas role="document" let-modal>
    <div class="modal-content" [class.dark-modal]="themeService.isDark()">
        <div class="modal-header">
            <h5 [ngClass]="{'dark-modal-title': themeService.isDark()}"
             class="modal-title" id="myModalLabel">Diplomas del curso {{curso}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="cerrarModal()"></button>
        </div>
        <div class="modal-body">
            <table class="table table-bordered" [ngClass]="{'table-dark': themeService.isDark()}"
            *ngIf="!isMobile">
                <thead class="table-light" [ngClass]="{'table-dark': themeService.isDark()}">
                    <tr>
                        <th class="cal-curso">
                            <app-sorting-buttons [prop]="'alumno'" [isDark]="themeService.isDark()"
                            (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            Alumno</th>
                        <th class="cal-fecha">
                            <app-sorting-buttons [prop]="'fechaInicio'" [isDark]="themeService.isDark()"
                            (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            Fecha de Inicio
                        </th>
                        <th class="cal-fecha">
                            <app-sorting-buttons [prop]="'fechaFin'" [isDark]="themeService.isDark()"
                            (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            Fecha de Conclusión
                        </th>
                        <th class="cal-acciones">Diploma c/firma</th>
                        <th class="cal-acciones">Diploma s/firma</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let diploma of diplomas | paginate : currentPage : pageSize">
                        <td class="product-name cal-curso">
                            {{diploma.alumno }}
                        </td>
                        <td class="product-name cal-fecha">
                            {{dateService.formatearSoloFecha(diploma.fechaInicio)}}
                        </td>
                        <td class="product-name cal-fecha">
                            {{dateService.formatearSoloFecha(diploma.fechaFin) }}
                        </td>
                        <td class="cal-acciones">
                            <a [href]="diploma.urlDiploma" target="_blank" class="btn btn-sm btn-primary"
                            (click)="removeFocus($event)" >
                                <i class='bx bx-show'></i>
                            </a>
                        </td>
                        <td class="cal-acciones">
                            <a [href]="diploma.urlDiplomaSinFirma" target="_blank" 
                            class="btn btn-sm btn-primary" (click)="removeFocus($event)">
                                <i class='bx bx-show'></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
             <!-- Estructura para dispositivos móviles -->
       <div *ngIf="isMobile">
        <div *ngFor="let item of diplomas | paginate : currentPage : 
        pageSize" class="accordion">
          <div class="accordion-header" (click)="toggleExpand(item)">
            <span class="item-header">{{item.alumno}}</span>
            <button class="btn btn-sm btn-secondary btn-header">
              <i [class]="item.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
            </button>
          </div>
          <div class="accordion-content" *ngIf="item.isExpanded">
            <strong> Fecha de inicio: </strong><span>{{dateService.formatearSoloFecha(item.fechaInicio)}}</span><br>
            <strong>Fecha de Conclusion: </strong><span>{{dateService.formatearSoloFecha(item.fechaFin)}}</span><br>
            <strong>Diploma c/firma: </strong><a href="{{item.urlDiploma}}" target="_blank" class="btn btn-sm btn-primary">
                <i class='bx bx-show'></i>
            </a><br>
            <strong>Diploma s/firma: </strong><a href="{{item.urlDiploma}}" target="_blank" class="btn btn-sm btn-primary">
                <i class='bx bx-show'></i>
            </a><br>
          </div>
        </div>
      </div>
            <app-paginacion *ngIf="diplomas.length > pageSize" [paginaActual]="currentPage" [elementosPorPagina]="pageSize" [totalElemetos]="diplomas.length"
                (cambioPagina)="onPageChange($event)">
            </app-paginacion>
        </div>
    </div>
</ng-template>
