import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { EvaluacionesService } from "../service/evaluaciones.service";

export const encuestaGuard: CanActivateFn = (route, state) => {
  const evaluacionService = inject(EvaluacionesService);
  const router = inject(Router);
  const rolUsuario = localStorage.getItem("rolUsuario");
  const idAlumno = localStorage.getItem("idAlumno");

  console.log("entro en guard encuesta")

  if (rolUsuario === 'student' && idAlumno) {
    evaluacionService
      .validarEncuestasPendientes(idAlumno)
      .toPromise()
      .then((encuestaPendiente) => {
        if (encuestaPendiente) {
          router.navigate(["/cursos"], {
            queryParams: { encuestaPendiente: true },
          });
          
          return false;
        }
      });
  }

  return true;
};
