<div class="login-area" [class.dark-login-area]="themeService.isDark()">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0 image-left" *ngIf="!showImage">
            <div class="login-image" >
                <img src="assets/img/login-bg.jpg" alt="login-bg">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo" [class.d-none]="themeService.isDark()">
                                <a routerLink="/">
                                    <img src="assets/img/logo-tec.png" alt="image">
                                </a>
                            </div>
                            <div class="logo d-none" [class.d-block]="themeService.isDark()">
                                <a routerLink="/">
                                    <img src="assets/img/logo-tec.png" alt="image">
                                </a>
                            </div>
                            <h3>TG Alumnos</h3>
                            <p [class.dark-text]="themeService.isDark()">Bienvenido, aquí encontrarás los recursos necesarios para tu aprendizaje.</p>
                            <form (ngSubmit)="iniciarSesion()">
                                <div class="form-group">
                                    <input [(ngModel)]="loginForm.email" [ngModelOptions]="{standalone: true}" type="email" placeholder="Correo" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input [(ngModel)]="loginForm.password" [ngModelOptions]="{standalone: true}" [type]="passwordFieldType" placeholder="Contraseña" class="form-control">
                                    <i class='bx' [ngClass]="{'bxs-low-vision': !passwordVisible, 'bxs-show': passwordVisible}" (click)="togglePasswordVisibility()"></i>
                                </div>
                                <button type="submit" [disabled]="enviadoForm">Iniciar Sesión</button>
                                <div class="forgot-password">
                                    <a routerLink="/recuperar-contrasena">Olvide mi contraseña</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

