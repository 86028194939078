export interface Cursos {
    idCurso: number;
    nombre: string;
    urlCurso: string;
    banner: string;
    instructor: string;
    locacion: string;
    modalidad: string;
    asistencias: string;
    noMateralCompartido: string;
    statusCalendario: string;
    fechaInicio: string;
    fechaFin: string;
    horaInicio: string;
    horaFin: string;
    idCalendario: number;
    encuestaIntermedia: boolean;
    encuestaFinal:boolean;
    objetivo?: string;
    temasPrincipales?: string;
    urlTemario?: string;
    historial?: boolean
}

export interface CursoDetalle{
    nombre: string;
    id: number;
    idCalendario: number
}

export const mapearCursosAlumnoInstructor = (calendario: any) => {
    const curso: Cursos = {
        idCurso: calendario.curso.idCurso,
        nombre: calendario.curso.nombre,
        urlCurso: calendario.curso.url,
        banner: calendario.curso.urlBanner,
        instructor: null,
        locacion: null,
        modalidad: calendario.modalidad,
        asistencias: null,
        noMateralCompartido: null,
        statusCalendario: calendario.estatusCalendario,
        fechaInicio: calendario.fechaInicio,
        fechaFin: calendario.fechaFin,
        horaInicio: calendario.horaInicio,
        horaFin: calendario.horaFin,
        idCalendario: calendario.idCalendario,
        historial: calendario.historial,
        encuestaIntermedia: validarEncuesta(calendario.curso.encuestas,"Intermedia"),
        encuestaFinal: validarEncuesta(calendario.curso.encuestas,"Final")
    };

    return curso;
};

export const mapearCursosAdmin = (data: any) => {
    const curso: Cursos = {
        idCurso: data.idCurso,
        nombre: data.nombre,
        urlCurso: data.url,
        banner: data.urlBanner,
        instructor: null,
        locacion: null,
        modalidad: null,
        asistencias: null,
        noMateralCompartido: null,
        statusCalendario: null,
        fechaInicio: null,
        fechaFin: null,
        horaInicio: null,
        horaFin: null,
        idCalendario: null,
        encuestaIntermedia: false,
        encuestaFinal: false
    };

    return curso;
};

export const mapearCursosIniciados = (data: any) => {
    const curso: Cursos = {
        idCurso: data.idCurso,
        nombre: data.nombreCurso,
        urlCurso: extraerUrlCurso(data.url),
        banner: data.url,
        instructor: data.instructor,
        locacion: null,
        modalidad: null,
        asistencias: null,
        noMateralCompartido: null,
        statusCalendario: null,
        fechaInicio: data.fechaInicio,
        fechaFin: data.fechaFin,
        horaInicio: null,
        horaFin: null,
        idCalendario: data.idCalendario,
        encuestaIntermedia: false,
        encuestaFinal: false
    };

    return curso;
};

export const mapearCursosProximos= (data: any) => {
    const curso: Cursos = {
        idCurso: null,
        nombre: data.nombreCurso,
        urlCurso: null,
        banner: data.bannerCurso,
        instructor: data.nombreInstructor,
        locacion: data.locacion,
        modalidad: data.modalidad,
        asistencias: null,
        noMateralCompartido: null,
        statusCalendario: data.estatusCalendario,
        fechaInicio: data.fechaInicio,
        fechaFin: null,
        horaInicio: null,
        horaFin: null,
        idCalendario: data.idCalendario,
        encuestaIntermedia: false,
        encuestaFinal: false,
        objetivo: data.objetivo,
        temasPrincipales: data.temasPrincipales,
        urlTemario: data.urlTemario
    };

    return curso;
};

const extraerUrlCurso = (url: string): string | null =>{
    const regex = /\/([^\/]+)\.jpg$/;

    const match = url.match(regex);

    return match ? match[1] : null;
}
const validarEncuesta = (encuestas: any[], evaluacion: string):boolean => {
    if(!encuestas || encuestas.length === 0){
        return false;
    }

    const evaluacionEncontrada = encuestas.filter(encuesta => encuesta.tipoEncuesta === evaluacion)[0];
    return evaluacionEncontrada && !evaluacionEncontrada.status;
}
