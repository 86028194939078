import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { BibliotecaService } from 'src/app/service/biblioteca.service';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginacionService } from 'src/app/service/util/paginacion.service';
import { FechasService } from 'src/app/service/util/fechas.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InicioService } from 'src/app/service/inicio.service';

@Component({
    selector: 'app-biblioteca',
    standalone: true,
    templateUrl: './biblioteca.component.html',
    styleUrls: ['./biblioteca.component.scss'],
    imports: [RouterLink, SidebarMenuComponent, CommonModule, FormsModule],
    providers: [DatePipe, BsModalService]
})
export class BibliotecaComponent implements OnInit {
    isToggled = false;
    loading: boolean = true;
    tipoUsuario: string = localStorage.getItem("rolUsuario");
    showAlert: boolean = true;
    bibliotecas: any[] = [];
    filtroBiblioteca: string = "";
    modalRef: BsModalRef | undefined;
    @ViewChild('accesoBibliotecaModal') accesoBibliotecaModal!: TemplateRef<any>;
    mostrarModal: boolean
    terminosCondiciones: boolean = false;
    displayTerminosCondiciones: boolean = false;
    currentOrder: string = 'recientes';
    currentLanguage: string = 'all';
    bloquearAcceso: boolean = false;
    idAlumno: any

    // Paginación
    paginatedTalleres: any[] = [];
    currentPage: number = 1;
    itemsPerPage: number = 12;
    searchText: string = '';
    filteredTalleres: any[] = [];
    

    constructor(public themeService: ThemeCustomizerService, private bibliotecaService: BibliotecaService,
        private datePipe: DatePipe, private paginationService: PaginacionService, 
        private fechasService: FechasService, private modalService: BsModalService, private inicioService: InicioService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.idAlumno = localStorage.getItem("idAlumno");
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        this.obtenerBibliotecas()
    }

    obtenerBibliotecas() {
        this.filtroBiblioteca = "";

        this.bibliotecaService.todas().subscribe(bibliotecas => {
            console.log(bibliotecas, 'bibliotecas');
            this.terminosCondiciones = bibliotecas['terminosAceptados'];
            this.displayTerminosCondiciones = !bibliotecas['terminosAceptados'];
            this.bibliotecas = bibliotecas['talleres'];
            this.filteredTalleres = this.bibliotecas;
            this.bloquearAcceso = !bibliotecas['permitirAcceso'];
            this.loading = false;
            this.updatePaginatedTalleres();
        }, error => {
            this.loading = false;
            // Manejar error
        });
    }

    verTaller(item: any){
        console.log(item)
    }

    formatearFechaConHora(fechaHora: string): string {
        return this.fechasService.formatearFechaConHora(fechaHora);
    }

    updatePaginatedTalleres() {
        this.paginatedTalleres = this.paginationService.actualizarElementosPagina(this.filteredTalleres);
    }

    goToPage(page: number) {
        this.paginationService.establecerPaginaActual(page);
        this.currentPage = page; // Actualiza el estado local del componente
        this.updatePaginatedTalleres();
    }

    getCurrentRangeStart(): number {
        return this.paginationService.obtenerRangoInicio();
    }

    getTotalPages(): number {
        return this.paginationService.obtenerTotalPaginas(this.filteredTalleres.length);
    }

    getCurrentRangeEnd(): number {
        return this.paginationService.obtenerRangoFin(this.filteredTalleres.length);
    }

    getVisiblePages(): (number | string)[] {
        return this.paginationService.obtenerPaginasVisibles(this.filteredTalleres.length);
    }

    onSearchTextChange(event: KeyboardEvent) {
        const inputElement = event.target as HTMLInputElement;
        this.searchText = inputElement.value || '';
        this.filterTalleres();
    }

    filterTalleres() {
        const searchTextLower = this.searchText.toLowerCase();
        this.filteredTalleres = this.bibliotecas.filter(taller =>
            taller.nombre.toLowerCase().includes(searchTextLower)
        );
        this.currentPage = 1; // Reiniciar a la primera página
        this.updatePaginatedTalleres();
    }

    ordenarTalleres(tipo: string) {
        switch (tipo) {
            case 'mayorDuracion':
                this.filteredTalleres.sort((a, b) => b.duracion - a.duracion);
                break;
            case 'menorDuracion':
                this.filteredTalleres.sort((a, b) => a.duracion - b.duracion);
                break;
            case 'recientes':
            default:
                this.filteredTalleres.sort((a, b) => new Date(b.fechaHora).getTime() - new Date(a.fechaHora).getTime());
                break;
        }
        this.updatePaginatedTalleres();
    }

    filtrarTalleresPorIdioma(idioma: string) {
        if (idioma === 'Espanol') {
            this.filteredTalleres = this.bibliotecas.filter(taller => !taller.ingles);
        } else if (idioma === 'Ingles') {
            this.filteredTalleres = this.bibliotecas.filter(taller => taller.ingles);
        } else {
            this.filteredTalleres = [...this.bibliotecas];
        }
        this.ordenarTalleres(this.currentOrder);
        this.updatePaginatedTalleres();
    }

    clearFilters() {
        this.searchText = '';
        this.currentOrder = 'recientes';
        this.currentLanguage = 'all';
        this.ordenarTalleres('recientes');
        this.filteredTalleres = [...this.bibliotecas];
        this.updatePaginatedTalleres();
    }

    closeAlert() {
        this.showAlert = false;
    }
}

