<div class="recuperar-pass-area" [class.dark-recuperar-pass-area]="themeService.isDark()">
    <div class="row m-0">
        <div class="col-lg-12 col-md-12 p-0">
            <div class="recuperar-pass-content">
                <div class="d-table recuperar-pass-marco">
                    <div class="d-table-cell">
                        <div class="recuperar-pass-form">
                            <div class="logo" [class.d-none]="themeService.isDark()">
                                <a routerLink="/">
                                    <img src="assets/img/logo-tec.png" alt="image">
                                </a>
                            </div>
                            <div class="logo d-none" [class.d-block]="themeService.isDark()">
                                <a routerLink="/">
                                    <img src="assets/img/logo-tec.png" alt="image">
                                </a>
                            </div>
                            <h3>TG Alumnos</h3>
                            <p>{{ingresarPassword ? 'Ingrese su nueva contraseña (minimo 6 caracteres)' : 'Ingrese su correo electronico para restaurar la contraseña'}}</p>
                            <form (ngSubmit)="validarMetodoEjecutar()">
                                <div class="form-group">
                                    <input type="email" placeholder="Ingresa tu correo" class="form-control" [(ngModel)]="correo" [ngModelOptions]="{standalone: true}" [disabled]="ingresarPassword">
                                </div>
                                <div *ngIf="ingresarPassword">
                                    <div class="form-group">
                                        <input [type]="passwordFieldType" placeholder="Nueva contraseña" class="form-control" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
                                        <i class='bx' [ngClass]="{'bxs-low-vision': !passwordVisible, 'bxs-show': passwordVisible}" (click)="togglePasswordVisibility()"></i>
                                    </div>
                                    <div class="form-group">
                                        <input [type]="passwordConfirmFieldType" placeholder="Confirmar nueva Contraseña" class="form-control" [(ngModel)]="verifyNewPassword" [ngModelOptions]="{standalone: true}">
                                        <i class='bx' [ngClass]="{'bxs-low-vision': !passwordVisibleConfirm, 'bxs-show': passwordVisibleConfirm}" (click)="togglePasswordConfirmVisibility()"></i>
                                    </div>

                                </div>
                                <button type="submit">{{!ingresarPassword ? 'Enviar correo de recuperación' : 'Restablecer Contraseña'}}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
