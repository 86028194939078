<div class="modal" *ngIf="isVisible">
  <div class="modal-container">
    <div class="modal-content" [class.dark-modal]="themeService.isDark()">
      <div class="modal-header">
        <span style="font-weight: bold;" class="text-title-list">Promociones TecGurus</span>
        <button class="btn btn-close text-end" (click)="cerrarModal()">
          <!-- Cerrar -->
        </button>
      </div>
      <div class="modal-body">
          <div>
            <h3 class="descuento-texto">Descuento del <span class="porcentaje">50%</span> en <span class="todos-cursos">TODOS LOS CURSOS</span></h3>
          </div>
          <div>
            <span class="text-title-list">Haz click en el boton para dirigirte a WhatsApp e inscribirte al curso que desees</span>
          </div>
          <div class="lista-cursos">
            <ul *ngFor="let item of promocionesArray">
              <li class="item-list">
                <span class="text-title-list" style="font-weight: bold;">{{item.nombreCurso}}</span>
                <div style="display: inline-flex; width: 100%; justify-content: space-between;">
                  <img [src]="item.bannerCurso" class="image-list">
                  <div style="display: flex; flex-direction: column;">
                    <span class="text-title-list">{{item.locacion}}</span>
                    <span class="text-title-list">{{item.modalidad}}</span>
                  </div>
                  <button class="btn btn-sm btn-inscribirme" (click)="redirigirAWhatsapp(item)" >
                    <i class="bx bx-paper-plane"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
      </div>
      <!-- <div class="logo" style="width: 80px; height: 80px;">
        <div>
          <a routerLink="/" class="d-inline-block"><img src="assets/img/logo-tec.png" alt="image"></a>
        </div>
      </div> -->
      <div class="modal-footer">
        <!-- <button class="btn btn-inscribirme" (click)="redirigirAWhatsapp()">
          <i class="bx bx-paper-plane"></i>Inscribirme
        </button> -->
        <div class="logo" style="width: 80px; height: 80px;">
          <div>
            <a routerLink="/" class="d-inline-block"><img src="assets/img/logo-tec.png" alt="image"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  