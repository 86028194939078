<app-sidebar-menu></app-sidebar-menu>

<div class="my-dashboard-area" style="padding-top: 20px;" [class.dark-my-dashboard-area]="themeService.isDark()">
  <div class="container">
    <div *ngIf="tipoUsuario === 'Instructor'">
      <div class="statistics">
        <div class="chart-container">
          <canvas id="evaluacionesChart"></canvas>
        </div>
      </div>
    </div>
    <div class="myDashboard-content" [ngClass]="{'dark-theme': themeService.isDark()}">
      <!-- TABLA DE ADMINISTRADOR -->
      <h3 *ngIf="tipoUsuario === 'admin'">Evaluaciones</h3>
      <div *ngIf="tipoUsuario === 'admin'" class="orders-table">
        <table id="basic-datatable-admin" class="table table-sm table-bordered align-middle nowrap">
          <thead>
            <tr>
              <th>Nombre del curso
                <app-sorting-buttons [prop]="'curso'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
                <input type="text" class="filter-input" (keyup)="filterEvaluaciones($event, 'curso')"
                  placeholder="Filtrar por Nombre">
              </th>
              <th>Instructor
                <app-sorting-buttons [prop]="'instructor'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
                <input type="text" class="filter-input" (keyup)="filterEvaluaciones($event, 'instructor')"
                  placeholder="Filtrar por Instructor">
              </th>
              <th>Fecha Inicio
                <app-sorting-buttons [prop]="'fechaInicio'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Fecha fin
                <app-sorting-buttons [prop]="'fechaFin'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Eval. intermedias faltantes
                <app-sorting-buttons [prop]="'encuestasIntermediasFaltantes'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Eval. finales faltantes
                <app-sorting-buttons [prop]="'encuestasFinalesFaltantes'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Ver evaluaciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of evaluaciones | paginate : currentPage : pageSize">
              <td>{{ item.curso }}</td>
              <td>{{ item.instructor }}</td>
              <td>{{ item.fechaInicio | date: 'dd/MM/yyyy' }}</td>
              <td>{{ item.fechaFin | date: 'dd/MM/yyyy' }}</td>
              <td>{{ item.encuestasIntermediasFaltantes }}</td>
              <td>{{ item.encuestasFinalesFaltantes }}</td>
              <td class="text-center">
                <button pButton type="button" class="btn btn-primary btn-ver"
                  (click)="abrirModalDetallesEvaluacion(modalDetalles, item.idCalendario, item)">
                  <i class="bx bx-show"></i> Ver
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Estructura para dispositivos móviles -->
        <div *ngIf="isMobile">
          <input type="text" class="filter-input" (keyup)="filterEvaluaciones($event, 'curso')"
                  placeholder="Filtrar por Nombre">
          <div *ngFor="let item of evaluaciones | paginate : currentPage : pageSize" class="accordion">
            <div class="accordion-header" (click)="toggleExpand(item)">
              <span>{{item.curso}}</span>
              <button class="btn btn-sm btn-secondary">
                <i [class]="item.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
              </button>
            </div>
            <div class="accordion-content" *ngIf="item.isExpanded">
              <strong>Instructor: </strong>{{item.instructor}}<br>
              <strong>Fecha Inicio: </strong>{{ item.fechaInicio | date: 'dd/MM/yyyy' }}<br>
              <strong>Fecha Fin: </strong>{{ item.fechaFin | date: 'dd/MM/yyyy' }}<br>
              <strong>Eval. intermedias faltantes: </strong>{{ item.encuestasIntermediasFaltantes }}<br>
              <strong>Eval. finales faltantes: </strong>{{ item.encuestasFinalesFaltantes }}<br>
              <div>
                <strong>Ver evaluaciones:</strong>
                <button pButton type="button" class="btn btn-primary btn-ver"
                  (click)="abrirModalDetallesEvaluacion(modalDetalles, item.idCalendario, item)">
                  <i class="bx bx-show"></i> Ver
                </button>
              </div>
            </div>
          </div>
        </div>

        <app-paginacion [paginaActual]="currentPage" [elementosPorPagina]="pageSize"
          [totalElemetos]="evaluaciones.length" (cambioPagina)="onPageChange($event)">
        </app-paginacion>
      </div>
      <!-- FIN TABLA DE ADMINISTRADOR -->

      <!-- TABLA DE INSTRUCTOR -->
      <h3 *ngIf="tipoUsuario === 'Instructor'">Mis evaluaciones</h3>
      <div *ngIf="tipoUsuario === 'Instructor'" class="orders-table table-responsive">
        <table id="basic-datatable" class="table table-sm table-bordered align-middle nowrap">
          <thead>
            <tr>
              <th>Nombre del curso
                <app-sorting-buttons [prop]="'nombreCurso'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Calificación intermedia
                <app-sorting-buttons [prop]="'promedioEncuestaIntermedia'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Calificación final
                <app-sorting-buttons [prop]="'promedioEncuestaFinal'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Fecha de inicio
                <app-sorting-buttons [prop]="'fechaInicio'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Fecha de fin
                <app-sorting-buttons [prop]="'fechaFin'" [isDark]="themeService.isDark()"
                  (sortEvent)="sortEvaluaciones($event.prop, $event.order)"></app-sorting-buttons>
              </th>
              <th>Ver detalles</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let evaluacion of evaluacionesSinAgrupar | paginate : currentPage : pageSize">
              <td>{{ evaluacion.nombreCurso }}</td>
              <td>{{ evaluacion.promedioEncuestaIntermedia | number: '1.2-2' }}</td>
              <td>{{ evaluacion.promedioEncuestaFinal | number: '1.2-2' }}</td>
              <td>{{ evaluacion.fechaInicio | date: 'dd/MM/yyyy' }}</td>
              <td>{{ evaluacion.fechaFin | date: 'dd/MM/yyyy' }}</td>
              <td>
                <button (click)="abrirModalDetallesEvaluacion(modalDetalles, evaluacion.idCalendario, evaluacion)"
                  class="btn btn-sm btn-primary"><i class="bx bx-show"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Estructura para dispositivos móviles -->
        <div *ngIf="isMobile">
          <input type="text" class="filter-input" (keyup)="filterEvaluaciones($event, 'nombreCurso')"
                  placeholder="Filtrar por Nombre">
          <div *ngFor="let evaluacion of evaluacionesSinAgrupar | paginate : currentPage : pageSize" class="accordion">
            <div class="accordion-header" (click)="toggleExpand(evaluacion)">
              <span>{{evaluacion.nombreCurso}}</span>
              <button class="btn btn-sm btn-secondary">
                <i [class]="evaluacion.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
              </button>
            </div>
            <div class="accordion-content" *ngIf="evaluacion.isExpanded">
              <strong>Calificacion intermedia: </strong>{{ evaluacion.promedioEncuestaIntermedia | number: '1.2-2'
              }}<br>
              <strong>Calificacion final: </strong>{{ evaluacion.promedioEncuestaFinal | number: '1.2-2' }}<br>
              <strong>Fecha Inicio: </strong>{{ evaluacion.fechaInicio | date: 'dd/MM/yyyy' }}<br>
              <strong>Fecha Fin: </strong>{{ evaluacion.fechaFin | date: 'dd/MM/yyyy' }}<br>
              <div>
                <strong>Ver detalles:</strong>
                <button (click)="abrirModalDetallesEvaluacion(modalDetalles, evaluacion.idCalendario, 
                    evaluacion)" class="btn btn-sm btn-primary"><i class="bx bx-show"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <app-paginacion [paginaActual]="currentPage" [elementosPorPagina]="pageSize"
          [totalElemetos]="evaluacionesSinAgrupar.length" (cambioPagina)="onPageChange($event)">
        </app-paginacion>
      </div>
      <!-- FIN TABLA DE INSTRUCTOR -->
    </div>
  </div>
</div>


<!-- MODAL EVALUACIONES USUARIOS
<ng-template #detallesUsuario role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel">Detalles del Usuario</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef?.hide()"></button>
  </div>
  <div class="card-body">
    <form [formGroup]="formAbrirDetalles">
      <div class="mb-3 row">
        <strong class="col-md-5 col-form-label centered-label">Id: </strong>
        <input style="width: 100px;" readonly formControlName="id" type="text">
      </div>
      <div class="mb-3 row">
        <strong class="col-md-5 col-form-label centered-label">Nombre: </strong>
        <input style="width: 200px;" readonly formControlName="nombre" type="text">
      </div>
      <div class="mb-3 row">
        <strong class="col-md-5 col-form-label centered-label">Email personal: </strong>
        <input style="width: 250px;" readonly formControlName="email" type="text">
      </div>
      <div class="mb-3 row">
        <strong class="col-md-5 col-form-label centered-label">Role: </strong>
        <input style="width: 100px;" readonly formControlName="role" type="text">
      </div>

    </form>

  </div>
</ng-template> -->


<!-- MODAL DETALLES EVALUACION -->
<ng-template #modalDetalles role="document" let-modal>
  <div class="modal-tema evaluaciones-modal" [ngClass]="{'dark-theme': themeService.isDark()}">
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel" style="font-size: 16px;">Curso: {{nombreCurso}}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="cerrarModalDetallesEvaluacion()"></button>
    </div>
    <div class="modal-body">
      <h3 style="font-size: 20px;">Detalles de las evaluaciones</h3>
      <table class="table-bordered table-evaluaciones-modal" *ngIf="!isMobile" style="border-color: #dee2e6;">
        <thead>
          <tr>
            <th *ngIf="tipoUsuario === 'admin'">Nombre del alumno
              <app-sorting-buttons [prop]="'nombreAlumno'" [isDark]="themeService.isDark()"
                (sortEvent)="sortDetalles($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Tipo evaluacion
              <app-sorting-buttons [prop]="'tipoEncuesta'" [isDark]="themeService.isDark()"
                (sortEvent)="sortDetalles($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Calificacion
              <app-sorting-buttons [prop]="'calificacion'" [isDark]="themeService.isDark()"
                (sortEvent)="sortDetalles($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th>Fecha de fin
              <app-sorting-buttons [prop]="'fechaRealizada'" [isDark]="themeService.isDark()"
                (sortEvent)="sortDetalles($event.prop, $event.order)"></app-sorting-buttons>
            </th>
            <th style="width: 5%;">Ver evaluaciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of detallesEvaluaciones | paginate : currentPageModalDetalles : pageSizeModalDetalles">
            <td *ngIf="tipoUsuario === 'admin'">{{item.nombreAlumno}}</td>
            <td>{{item.tipoEncuesta}}</td>
            <td>{{item.calificacion | number: '1.2-2' }}</td>
            <td>{{item.fechaRealizada | date: 'dd/MM/yyyy hh:mm a'}}</td>
            <td class="text-center" style="width: 5%;">
              <button class="btn btn-sm btn-primary" (click)="abrirModalEvaluacion(modalEvaluacion, item.idEncuesta)">
                <i class="bx bx-show"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
       <!-- Estructura para dispositivos móviles -->
       <div *ngIf="isMobile">
        <div *ngFor="let evaluacion of detallesEvaluaciones | paginate : currentPageModalDetalles : 
        pageSizeModalDetalles" class="accordion">
          <div class="accordion-header" (click)="toggleExpand(evaluacion)">
            <span *ngIf="tipoUsuario === 'admin'">{{evaluacion.nombreAlumno}}</span>
            <span *ngIf="tipoUsuario === 'Instructor'">{{evaluacion.tipoEncuesta}}</span>
            <button class="btn btn-sm btn-secondary">
              <i [class]="evaluacion.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
            </button>
          </div>
          <div class="accordion-content" *ngIf="evaluacion.isExpanded">
            <strong *ngIf="tipoUsuario === 'admin'">Tipo evaluacion: </strong><span *ngIf="tipoUsuario === 'admin'">
              {{evaluacion.tipoEncuesta}}</span><br>
            <strong>Calificacion: </strong>{{evaluacion.calificacion | number: '1.2-2' }}<br>
            <strong>Fecha de Fin: </strong>{{ evaluacion.fechaRealizada | date: 'dd/MM/yyyy hh:mm a'}}<br>
            <div>
              <strong>Ver evaluaciones:</strong>
              <button class="btn btn-sm btn-primary" (click)="abrirModalEvaluacion(modalEvaluacion, evaluacion.idEncuesta)">
                <i class="bx bx-show"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-paginacion [paginaActual]="currentPageModalDetalles" [elementosPorPagina]="pageSizeModalDetalles"
      [totalElemetos]="detallesEvaluaciones.length" (cambioPagina)="onPageChangeModalDetalles($event)">
    </app-paginacion>
  </div>
</ng-template>


<!-- MODAL EVALUACION -->
<ng-template #modalEvaluacion role="document" let-modal>
  <div class="modal-tema template-detalles" [ngClass]="{'dark-theme': themeService.isDark()}">
    <div class="detalles" [ngClass]="{'dark-theme': themeService.isDark(),
    'detalles-dark' : themeService.isDark()}">
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel" style="font-size: 16px;">Detalle de la evaluación</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="cerrarModalEvaluacion()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="tipoUsuario === 'admin'" class="datos-evaluacion">
      <div class="datos-bloque">
        <label class="fw-bold">Curso:</label>
        <span>{{' ' + datosEncuesta.curso}}</span>
      </div>
      <div class="datos-bloque">
        <label class="fw-bold">Instructor:</label>
        <span>{{' ' +datosEncuesta.instructor}}</span>
      </div>
      <div class="datos-bloque">
        <label class="fw-bold">Alumno:</label>
        <span>{{' ' +detalleEvaluacion.alumno}}</span>
      </div>
      <div class="datos-bloque">
        <label class="fw-bold">Modalidad:</label>
        <span>{{' ' +datosEncuesta.modalidad}}</span>
      </div>
    </div>
    <h5 style="font-size: 20px; margin-top: 10px;">Respuestas de la evaluación</h5>
    <div *ngFor="let respuesta of detalleEvaluacion.respuestas" class="pregunta-respuesta">
      <div
        *ngIf="respuesta && (respuesta.pregunta !== '' || respuesta.respuesta !== '' || respuesta.comentario !== '')">
        <div class="preguntas-respuestas">
          <div *ngIf="respuesta.pregunta !== ''" class="pregunta">{{ respuesta.pregunta }}</div>
          <div *ngIf="respuesta.respuesta !== ''" class="respuesta">{{ respuesta.respuesta }}</div>
          <div *ngIf="respuesta.comentario && respuesta.comentario !== ''" class="respuesta">{{ respuesta.comentario
            }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
  
</ng-template>