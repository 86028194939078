export interface Calendario {
    idCalendario: number;
    instructor: string;
    fechaInicio: string;
    fechaFin: string;
    modalidad: string;
    curso: string;
    idCurso: number;
    url: string;
}

export const mapearResponseACalendario = (data: any):Calendario => {
    console.log(data)
    const calendario : Calendario = {
        idCalendario: data.idCalendario,
        curso: data.nombreCurso,
        modalidad: data.modalidad,
        fechaInicio: data.fechaInicio,
        fechaFin: data.fechaFin,
        instructor: data.instructor,
        idCurso: data.idCurso,
        url: data.url
    }

    return calendario;
}
