<app-sidebar-menu></app-sidebar-menu>

<div class="cart-area ptb-100" [class.dark-cart-area]="themeService.isDark()">
    <div class="container">
        <div class="myDashboard-content">
            <div *ngIf="showAlert" class="custom-alert">
                <i class='bx bx-info-circle'></i>
                <div class="alert-text">
                    No estas registrado como alumno.
                </div>
                <button type="button" class="close-btn" aria-label="Close" (click)="closeAlert()">✖</button>
            </div>
            <div class="header">
                <h4>{{!mostrarAdmin ? "Mis Diplomas": "Cursos Finalizados"}}</h4>
                <div *ngIf="mostrarAdmin" class="topbar-ordering-and-search">
                    <div class="topbar-search">
                        <form (ngSubmit)="nuevoFiltro(false)" class="form-search">
                            <label><i class="bx bx-search"></i></label>
                            <input type="text" class="input-search" placeholder="Buscar aqui..."
                                [(ngModel)]="nombreCursoFiltro" name="search">
                            <div class="filter-buttons">
                                <button type="submit" [disabled]="!nombreCursoFiltro"
                                    class="btn btn-sm btn-primary order-button-icon">
                                    Buscar curso
                                </button>
                                <button type="button" class="btn btn-sm btn-danger order-button-icon"
                                    (click)="nuevoFiltro(true)">
                                    Borrar filtro
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="recent-orders-table">
                <table class="table table-bordered" [ngClass]="{'table-dark': themeService.isDark()}">
                    <thead class="table-light" [ngClass]="{'table-dark': themeService.isDark()}">
                        <tr>
                            <th class="cal-curso">Curso
                                <app-sorting-buttons [prop]="'curso'" [isDark]="themeService.isDark()"
                                    (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            </th>
                            <th class="cal-instructor" *ngIf="mostrarAdmin">Instructor
                                <app-sorting-buttons [prop]="'instructor'" [isDark]="themeService.isDark()"
                                    (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            </th>
                            <th class="cal-modalidad" *ngIf="mostrarAdmin">Modalidad
                                <app-sorting-buttons [prop]="'modalidad'" [isDark]="themeService.isDark()"
                                    (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            </th>
                            <th class="cal-fecha">Fecha de Inicio
                                <app-sorting-buttons [prop]="'fechaInicio'" [isDark]="themeService.isDark()"
                                    (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            </th>
                            <th class="cal-fecha">Fecha de Conclución
                                <app-sorting-buttons [prop]="'fechaFin'" [isDark]="themeService.isDark()"
                                    (sortEvent)="sortDiplomas($event.prop, $event.order)"></app-sorting-buttons>
                            </th>
                            <th class="cal-acciones">{{!mostrarAdmin ? "Visualizar" : "Detalles"}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let diploma of diplomas | paginate : currentPageItems : pageSize">
                            <td class="product-name cal-curso">
                                {{diploma.curso }}
                            </td>
                            <td class="product-name cal-instructor" *ngIf="mostrarAdmin">
                                {{diploma.instructor }}
                            </td>
                            <td class="product-name cal-modalidad" *ngIf="mostrarAdmin">
                                {{diploma.modalidad }}
                            </td>
                            <td class="product-name cal-fecha">
                                {{dateService.formatearSoloFecha(diploma.fechaInicio)}}
                            </td>
                            <td class="product-name cal-fecha">
                                {{dateService.formatearSoloFecha(diploma.fechaFin) }}
                            </td>
                            <td class="cal-acciones" *ngIf="!mostrarAdmin">
                                <a href="{{diploma.urlDiploma}}" target="_blank" class="btn btn-sm btn-primary">
                                    <i class='bx bx-show'></i>
                                </a>
                            </td>
                            <td class="cal-acciones" *ngIf="mostrarAdmin">
                                <button class="btn btn-sm btn-primary"
                                    (click)="obtenerDiplomasCalendario(diploma.idCalendario)"><i
                                        class='bx bx-info-circle'></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span *ngIf="diplomas.length === 0">Actualmente no tienes diplomas</span>

                <!-- Estructura para dispositivos móviles -->
                <div *ngIf="isMobile">
                    <div *ngFor="let diploma of diplomas | paginate : currentPageItems : pageSize" class="accordion">
                        <div class="accordion-header" (click)="toggleExpand(diploma)">
                            <span>{{diploma.curso}}</span>
                            <button class="btn btn-sm btn-secondary">
                                <i [class]="diploma.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
                            </button>
                        </div>
                        <div class="accordion-content" *ngIf="diploma.isExpanded">
                            <div *ngIf="mostrarAdmin">
                                <strong>Instructor: </strong>{{diploma.instructor}}<br>
                                <strong>Modalidad: </strong>{{diploma.modalidad}}<br>
                            </div>
                            <strong>Fecha de Inicio:
                            </strong>{{dateService.formatearSoloFecha(diploma.fechaInicio)}}<br>
                            <strong>Fecha de Conclusión:
                            </strong>{{dateService.formatearSoloFecha(diploma.fechaFin)}}<br>
                            <div *ngIf="!mostrarAdmin">
                                <a href="{{diploma.urlDiploma}}" target="_blank" class="btn btn-sm btn-primary">
                                    <i class='bx bx-show'></i>
                                </a>
                            </div>
                            <div *ngIf="mostrarAdmin">
                                <strong style="margin-right: 1%;">{{!mostrarAdmin ? "Visualizar:" : "Detalles:"}}</strong>
                                <button class="btn btn-sm btn-primary"
                                    (click)="obtenerDiplomasCalendario(diploma.idCalendario)">
                                    <i class='bx bx-info-circle'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <span style="margin-top: 10px;" *ngIf="diplomas.length === 0">Actualmente no tienes diplomas</span>
                </div>

            </div>
            <app-paginacion *ngIf="diplomas.length >= pageSize" [paginaActual]="currentPage"
                [elementosPorPagina]="pageSize" [totalElemetos]="totalRegistros" (cambioPagina)="onPageChange($event)">
            </app-paginacion>
        </div>
    </div>
</div>

<app-modal-diplomas-calendario [diplomas]="diplomasCalendario"></app-modal-diplomas-calendario>