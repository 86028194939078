<app-sidebar-menu></app-sidebar-menu>

<div class="courses-details-area pt-30 pb-70" [class.dark-courses-details-area]="themeService.isDark()">
  <div class="container">
    <div class="funfacts-area">
      <div class="funfacts-container">
        <div class="funfacts-inner">
          <div class="row contenedor-info">
            <div class="col-lg-9 col-md-12 col-6">
              <div class="single-funfact">
                <h3><span>Actividad del curso:</span></h3>
                <p>({{calendario.curso? calendario.curso : ''}})</p>
              </div>
            </div>
            <div class="col-lg-9 col-md-12 col-6">
              <div class="single-funfact">
                <h3><span>Instructor:</span></h3>
                <p>{{calendario.instructor}}</p>
              </div>
            </div>
            <div class="col-lg-9 col-md-12 col-6">
              <div class="single-funfact">
                <h3><span>Fecha:</span></h3>
                <p>{{dateService.formatearFecha(calendario.fechaInicio, calendario.fechaFin)}}</p>
              </div>
            </div>
            <div class="col-lg-9 col-md-12 col-6">
              <div class="single-funfact">
                <h3><span>Modalidad:</span></h3>
                <p>{{calendario.modalidad}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row curso-info">
      <div class="curso-info-superior">
        <div *ngIf="rolUsuario !== 'student'" style="width: 33%;"
          [routerLink]="['/curso/material/',calendario.idCurso, calendario.curso, calendario.url, calendario.idCalendario]">
          <div class="services-box" style="cursor: pointer;">
            <div class="icon">
              <i class='bx bx-folder'></i>
            </div>
            <h3>Material Curso</h3>
            <p>Documentos del curso</p>
          </div>
        </div>
        <div *ngIf="rolUsuario !== 'student'" style="width: 33%;">
          <div class="services-box" style="cursor: pointer;"
            (click)="abrirModalNuevaPublicacion(nuevaPublicacion, null)">
            <div class="icon">
              <i class='bx bx-upload'></i>
            </div>
            <h3>Subir publicación</h3>
            <p>Da click para subir tareas y trabajos</p>
          </div>
        </div>
        <div style="width: 33%;">
          <a style="cursor: pointer;" (click)="redirigirAsistencia(calendario)">
            <div class="services-box">
              <div class="icon">
                <i class="bx bx-calendar"></i>
              </div>
              <h3>Mi Asistencia</h3>
              <p>Asistencia del dia: {{fechaHoy}}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="contenedor-foro-material">
      <div class="courses-details-desc">
        <div class="courses-review-comments">
          <h3>Publicaciones</h3>
          <div class="user-review" *ngFor="let publicacion of publicaciones; let j = index">
            <div class="parte-arriba">
              <div class="subparte-arriba">
                <div class="icon">
                  <i class='bx bxs-comment'></i>
                </div>
                <div class="subparte-arriba-datos">
                  <span class="d-block sub-comment">{{publicacion.nombre}} | {{publicacion.noComentarios}}
                    respuestas</span>
                  <p style="white-space: pre-line;" [innerHTML]="publicacion.texto"></p>
                  <div class="review-rating">
                    <span class="d-inline-block">{{dateService.formatearFechaConHora(publicacion.fechaHora)}}</span>
                  </div>
                </div>
              </div>
              <div class="publicacion-botones">
                <button (click)="abrirModalNuevaPublicacion(nuevaPublicacion, publicacion)" *ngIf="rolUsuario !== 'student'"
                  class="btn btn-sm btn-success boton-parte-arriba"><i class="bx bx-pencil"></i>Modificar</button>
                <button (click)="eliminarTarea(publicacion.idPublicacion)" *ngIf="rolUsuario !== 'student'"
                  class="btn btn-sm btn-danger boton-parte-arriba"><i class="bx bx-trash"></i>Eliminar</button>
                <button (click)="mostrarFormDetalles(publicacion, j)" *ngIf="mostrarFormulario !== j"
                  class="btn btn-sm btn-primary boton-parte-arriba"><i class="bx bx-show"></i>Ver comentarios</button>
                <button (click)="ocultarComentarios(j)" *ngIf="mostrarFormulario === j"
                  class="btn btn-sm btn-secondary boton-parte-arriba"><i class="bx bx-hide"></i>Ocultar
                  comentarios</button>
              </div>
            </div>

            <div class="modal-content detalles" [ngClass]="{'dark-theme': themeService.isDark(),
              'detalles-dark' : themeService.isDark()}" *ngIf="mostrarFormulario === j">
              <div>
                <div class="caja-comentarios">
                  <div>
                    <form #comentarioForm="ngForm" (ngSubmit)="guardarComentario(comentarioForm)"
                      style="margin-bottom: 20px;">
                      <label for="">Comentario:*</label>
                      <textarea class="textarea-agregar form-control" required name="descripcion" id=""
                        [(ngModel)]="textoComentario" placeholder="Publicar un comentario..."
                        #descripcionInput="ngModel"></textarea>
                      <div *ngIf="descripcionInput.invalid && descripcionInput.touched" class="text-danger">Comentario
                        es requerido.
                      </div>
                      <div class="mb-3 contenedor-total-archivos">
                        <label>Subir archivos</label>
                        <div class="custom-file-upload" [ngClass]="{'dark-theme': themeService.isDark()}">
                          <label for="file-upload" class="file-upload-label">
                            Seleccionar Archivos ({{archivos.length}})
                          </label>
                          <input id="file-upload" style="cursor: pointer;" class="custom-file-input" type="file"
                            (change)="onFileSelected($event)" required multiple #archivoInput>
                        </div>
                        <div *ngIf="archivoInput.invalid && archivoInput.touched" class="text-danger">Archivo es
                          requerido.</div>
                      </div>
                      <!-- Lista de archivos seleccionados -->
                      <ul *ngIf="archivos.length > 0" class="mt-3 custom-scrollbar">
                        <li *ngFor="let archivo of archivos; let i = index" class="mb-2 d-flex align-items-center">
                          <div *ngIf="esImagen(archivo.file)">
                            <img [src]="archivo.url" alt="{{ archivo.file.name }}"
                              style="max-width: 50px; max-height: 50px; border-radius: 5px; margin-right: 10px;">
                          </div>
                          <div *ngIf="!esImagen(archivo.file)">
                            <i class="bx bx-file" style="font-size: 24px; margin-right: 10px;"></i>
                          </div>
                          <div>{{ archivo.file.name }}</div>
                          <button type="button" class="btn btn-sm btn-danger ms-auto" (click)="eliminarArchivo(i)">
                            <i class="bx bx-trash"></i>
                          </button>
                        </li>
                      </ul>
                      <div class="textarea-buttons">
                        <button type="submit" class="btn btn-sm btn-outline-primary"
                          [disabled]="comentarioForm.invalid"><i class="bx bx-pencil"></i>Publicar</button>
                        <button class="btn btn-sm btn-outline-danger" (click)="descartarComentario()"
                          [disabled]="!textoComentario"><i class="bx bx-trash"></i>Descartar</button>
                      </div>
                    </form>
                  </div>


                </div>

                <span *ngIf="!comentarios.comentarios">
                  No hay comentarios
                </span>
                <div class="comments-list">
                  <h5>Comentarios</h5>
                  <ng-container *ngFor="let comentario of comentarios.comentarios">
                    <div class="comment-item">
                      <h3>{{ comentario.name }}</h3>
                      <p>{{ comentario.comentario }}</p>
                      <div class="archivos-adjuntos" style="width: 100%; word-wrap: break-word;">
                        <div *ngFor="let archivo of comentario.archivos" class="contenedor-archivo">
                          <ng-container *ngIf="esImagenMostrar(archivo.archivo)">
                            <img [src]="archivo.archivo" alt="{{ archivo.nombre }}" class="imagen-adjunta" />
                          </ng-container>
                          <ng-container *ngIf="!esImagenMostrar(archivo.archivo)">
                            <a href="{{archivo.archivo}}" download="{{archivo.nombre}}" class="archivo-adjunto">
                              <i class="bx bx-file"></i> {{ archivo.archivo }}
                            </a>
                          </ng-container>
                        </div>
                      </div>
                      <div class="hora-boton">
                        <span>{{ formatearFechaConHora(comentario.fechaHora) }}</span>
                        <!-- <div class="labels-comentarios">
                          <label class="reply-button">Responder</label>
                          <label class="reply-button">Citar</label>
                        </div> -->
                      </div>
                    </div>
                  </ng-container>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="courses-sidebar-syllabus">
        <h3 class="syllabus-title">Material del curso</h3>
        <div class="services-box services-box-material" *ngFor="let material of isMobile === true?
         (materiales | paginate : currentPage : pageSize) : materiales"
        style="cursor: pointer;" >
        <a href="{{material.archivo}}" download="{{material.nombre}}">
          <div class="icon">
            <i [ngClass]="material.icono"></i>
          </div>
          <h3>{{material.nombre}} ({{material.tipoMaterial}})</h3>
          <p>Compartido el: {{dateService.formatearFechaConHora(material.fechaHora)}}</p>
        </a>
         
        </div>
        <app-paginacion *ngIf="isMobile && materiales.length > pageSize" [paginaActual]="currentPage"
          [elementosPorPagina]="pageSize"
          [totalElemetos]="materiales.length"
          (cambioPagina)="onPageChange($event)">
      </app-paginacion>
        <span *ngIf="isMobile" style="color:#a1a4ad; font-size: 14px;">Mostrando {{materiales.length}} material(es)</span>
      </div>
    </div>
  </div>


  <!-- MODAL NUEVA PUBLICACION -->
  <ng-template #nuevaPublicacion role="document" let-modal>
    <div class="modal-content" [ngClass]="{'dark-theme': themeService.isDark()}">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">Mi trabajo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="cerrarModal()"></button>
      </div>
      <div class="modal-body">
        <span class="text-danger">*Campos requeridos | Peso máximo de archivos 100Mb</span>
        <form #publicacionForm="ngForm" (ngSubmit)="guardarNuevaPublicacion(publicacionForm)">
          <div class="mb-3">
            <label>Nombre*</label>
            <input class="form-control" type="text" [(ngModel)]="nombre" name="nombre" required #nombreInput="ngModel">
            <div *ngIf="nombreInput.invalid && nombreInput.touched" class="text-danger">Nombre es requerido.
            </div>
          </div>
          <div class="mb-3">
            <label>Descripcion*</label>
            <textarea class="form-control" [(ngModel)]="descripcion" name="descripcion" required
              #descripcionInput="ngModel"></textarea>
            <div *ngIf="descripcionInput.invalid && descripcionInput.touched" class="text-danger">Descripción es
              requerida.</div>
          </div>
          <div class="mb-3 contenedor-total-archivos">
            <label>Subir archivos</label>
            <div class="custom-file-upload" [ngClass]="{'dark-theme': themeService.isDark()}">
              <label for="file-upload" class="file-upload-label">
                Seleccionar Archivos ({{archivos.length}})
              </label>
              <input id="file-upload" style="cursor: pointer;" class="custom-file-input" type="file"
                (change)="onFileSelected($event)" required multiple #archivoInput>
            </div>
            <div *ngIf="archivoInput.invalid && archivoInput.touched" class="text-danger">Archivo es requerido.
            </div>
          </div>
          <!-- Lista de archivos seleccionados -->
          <ul style="list-style: none; padding-left: 0;" *ngIf="archivos.length > 0" class="mt-3">
            <li *ngFor="let archivo of archivos; let i = index" class="mb-2 d-flex align-items-center">
              <div *ngIf="esImagen(archivo.file)">
                <img [src]="archivo.url" alt="{{ archivo.file.name }}"
                  style="max-width: 50px; max-height: 50px; border-radius: 5px; margin-right: 10px;">
              </div>
              <div *ngIf="!esImagen(archivo.file)">
                <i class="bx bx-file" style="font-size: 24px; margin-right: 10px;"></i>
              </div>
              <div>{{ archivo.file.name }}</div>
              <button type="button" class="btn btn-sm btn-danger ms-auto" (click)="eliminarArchivo(i)">
                <i class="bx bx-trash"></i>
              </button>
            </li>
          </ul>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="publicacionForm.invalid">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>