<nav aria-label="breadcrumb" class="navarea-breadcumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of items; let last = last">
      <a *ngIf="!last; else lastBreadcrumb" [routerLink]="item.link">{{ item.title }}</a>
      <ng-template #lastBreadcrumb>
        <span>{{ item.title }}</span>
      </ng-template>
    </li>
  </ol>
</nav>
  
