import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  Cursos,
  mapearCursosAdmin,
  mapearCursosAlumnoInstructor,
} from "../interface/cursos";

@Injectable({
  providedIn: "root",
})
export class CursosService {
  private urlApi: string = `${environment.baseApi}`;

  constructor(private http: HttpClient) {}

  async obtenerCursos() {
    const rolUsuario = localStorage.getItem("rolUsuario");
    const id =
      rolUsuario === "Instructor"
        ? localStorage.getItem("idInstructor")
        : rolUsuario === "student"
        ? localStorage.getItem("idAlumno")
        : null;
    let response: any[];
    let responseIntermedias: any
    switch (rolUsuario) {
      case "admin":
        response = await this.obtenrCursosActivos();
        break;
      case "Instructor":
        response = await this.obtenrCursosInstructor(id, false);
        break;
      case "student":
        response = await this.obtenrCursosAlumno(id);
        responseIntermedias = await this.validarIntermediasAlumno(id)
        console.log('resoinse intermedais', responseIntermedias)
          localStorage.setItem("encuestaPendiente", responseIntermedias.toString());
        break;
      default:
        response = null;
        break;
    }

    return this.mapearResponse(response, rolUsuario);
  }

  async detallesCalendario(idCalendario: any) {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}calendario/datos-generales/${idCalendario}`, {
        headers,
      })
      .toPromise();
  }

  async obtenerPromociones() {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}cursos/modal-promociones`, {
        headers,
      })
      .toPromise();
  }

  cursosTerminadosPorPagina(noPagina: any) {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}calendario/cursos-terminados/${noPagina}`, {
        headers,
      })
      .toPromise();
  }
  cursosTerminadosPorPaginaFiltro(noPagina: any, filtro: string) {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}calendario/cursos-terminados/${noPagina}/${filtro}`, {
        headers,
      })
      .toPromise();
  }

  async cursosIniciados() {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}calendario/cursos-iniciados/`, { headers })
      .toPromise();
  }
  async cursosProximos() {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}cursos/proximos`, { headers })
      .toPromise();
  }
  async cursosHistorial() {
    const id = localStorage.getItem("idInstructor");
    return this.obtenrCursosInstructor(id, true);
  }

  private async obtenrCursosActivos(): Promise<any> {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}cursos/activos`, { headers })
      .toPromise();
  }

  private async obtenrCursosInstructor(idInstructor: string, historial: boolean): Promise<any> {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}instructor/cursos/${idInstructor}/${historial}`, { headers })
      .toPromise();
  }

  private async obtenrCursosAlumno(idAlumno: string): Promise<any> {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}inscripciones/alumno/${idAlumno}`, { headers })
      .toPromise();
  }

  private async validarIntermediasAlumno(idAlumno: string): Promise<any> {
    const headers = this.obtenerHeaders();
    return this.http
      .get(`${this.urlApi}inscripciones/intermedias/alumno/${idAlumno}`, { headers })
      .toPromise();
  }

  private mapearResponse(data: any[], rol: string): Cursos[] {
    if (data === null) {
      return null;
    }
    if (rol === "student") {
      const alumno = data[0];
      return alumno.inscripcion.map((i: any) =>
        mapearCursosAlumnoInstructor(i.calendario)
      );
    } else if (rol === "Instructor") {
      const instructor = data[0];
      return instructor.calendario.map((calen: any) =>
        mapearCursosAlumnoInstructor(calen)
      );
    }

    return data.map((curso: any) => mapearCursosAdmin(curso));
  }

  private obtenerHeaders() {
    return new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    });
  }
}
