import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';
import { SidebarMenuComponent } from '../../common/sidebar-menu/sidebar-menu.component';
import { HeaderStyleOneComponent } from '../../common/header-style-one/header-style-one.component';
import { CommonModule } from '@angular/common';
import { BsModalService} from 'ngx-bootstrap/modal';
import { UsuarioModel } from 'src/app/interface/usuario';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { PaginacionComponent } from '../../common/paginacion/paginacion.component';
import { PaginatePipe } from 'src/app/pipes/paginate.pipe';
import { PaginacionService } from 'src/app/service/util/paginacion.service';
import { UsuarioService } from 'src/app/service/usuario.service';

@Component({

    selector: 'app-usuarios-page',
    standalone: true,
    imports: [HeaderStyleOneComponent, RouterLink,SidebarMenuComponent,CommonModule, FormsModule,ReactiveFormsModule,PaginacionComponent,PaginatePipe],
    templateUrl: './usuarios-page.component.html',
    styleUrls: ['./usuarios-page.component.scss'],
    providers:[BsModalService]

})
export class UsuariosPageComponent implements OnInit {

  usuarios: any;
  usuarioSeleccionado: any;
  usuariosOriginales: any[] = [];
  modalRef: any;
  formAbrirDetalles: FormGroup;
  currentPage: number = 1;
  pageSize: number = 20;

  isToggled = false;
  evaluaciones: any;
  searchText: string = '';
  paginatedUsuarios: any;
  filteredUsuarios: any;
  subscription: any;
  rolUsuario: string;
  rolUsuarioModal: string;
  isMobile: boolean;
  correoSeleccionado: string = "";

  constructor(
    public themeService: ThemeCustomizerService,
    private usuarioService: UsuarioService,
    public paginacionService: PaginacionService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.rolUsuario = localStorage.getItem("rolUsuario");
    this.isMobile = window.innerWidth < 576;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 576;
      console.log('ismobile', this.isMobile)
    };  
  }


  ngOnInit(): void {
    this.subscription = this.usuarioService.todos().subscribe(
      usuarios => {
        this.usuarios = usuarios;
        this.usuariosOriginales = [...this.usuarios];
      },
      error => {
        console.error('Error al obtener usuarios:', error);

      }
    );
  }

  private inicializarFormDetalles(datos: any) {
    this.formAbrirDetalles = this.formBuilder.group({
      id: [datos.id],
      nombre: [datos.name],
      email: [datos.email],
      role: [datos.role],
    });
  }



  abrirModalDetalles(mostrarDetalles: any, datos: any) {
    this.modalRef = this.modalService.show(mostrarDetalles);
    this.inicializarFormDetalles(datos);
    this.rolUsuarioModal= datos.role;
  }

  closeModal() {
    this.modalRef.hide();
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;

  }



    // Método de filtrado
    filterUsuarios(event: KeyboardEvent, field: string) {
      const inputElement = event.target as HTMLInputElement;
      const searchTextLower = inputElement.value.toLowerCase();
      this.usuarios = this.usuariosOriginales.filter(usuario => {
        let valueToFilter = usuario;
        field.split('.').forEach(key => {
          valueToFilter = valueToFilter[key];
        });
        return valueToFilter && valueToFilter.toLowerCase().includes(searchTextLower);
      });
    }

        sortUsuarios(prop: string, order: 'asc' | 'desc') {
        this.usuarios = [...this.usuariosOriginales].sort((a, b) => {
        let aValue = prop.split('.').reduce((o, i) => o[i], a);
        let bValue = prop.split('.').reduce((o, i) => o[i], b);

        // Normalizar los valores para eliminar acentos y caracteres especiales
        aValue = aValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        bValue = bValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        if (aValue < bValue) {
          return order === 'asc' ? -1 : 1;
        } else if (aValue > bValue) {
          return order === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });

    }

    seleccionarItem(email: string) {
        this.correoSeleccionado =
          this.correoSeleccionado === ""
            ? email
            : this.correoSeleccionado === email
            ? ""
            : email;
      }
  }
