import { ThemeCustomizerService } from '../app/components/common/theme-customizer/theme-customizer.service';
import { Location, LocationStrategy, NgIf, PathLocationStrategy } from '@angular/common';
import { Router, NavigationCancel, NavigationEnd, RouterOutlet } from '@angular/router';
import { Component, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ThemeCustomizerComponent } from './components/common/theme-customizer/theme-customizer.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { AppBreadcrumbsComponent } from './components/common/breadcrumbs/breadcrumbs.component';
import { MenuItem, menuItemsAdmin, menuItemsAlumno, menuItemsInstructor } from './components/common/sidebar-menu/menu-items';
import { BreadcrumbsService } from './service/util/breadcrumbs.service';
import { HeaderStyleOneComponent } from "./components/common/header-style-one/header-style-one.component";
declare let $: any;

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ],
    imports: [ThemeCustomizerComponent, RouterOutlet, NgIf, AppComponent, FooterComponent, AppBreadcrumbsComponent, HeaderStyleOneComponent]
})
export class AppComponent {
    
    title: any;
    location: any;
    isToggled = false;
    routerSubscription: any;

    constructor(
        private router: Router,
        public themeService: ThemeCustomizerService,
        private renderer: Renderer2, private breadcrumbsService: BreadcrumbsService
    
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              const currentUrl = this.router.url;
              const whatsappButton = this.renderer.selectRootElement('#whatsapp-button', true);
      
              if (currentUrl.includes('/iniciar-sesion') || currentUrl.includes('/recuperar-contrasena')) {
                this.renderer.setStyle(whatsappButton, 'display', 'none');
              } else {
                this.renderer.setStyle(whatsappButton, 'display', 'block');
              }
            }
          });
        }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
    
}