import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditAccountPageComponent } from "./components/pages/edit-account-page/edit-account-page.component";
import { UsuariosPageComponent } from "./components/pages/usuarios-page/usuarios-page.component";
import { EvaluacionesPageComponent } from "./components/pages/evaluaciones-page/evaluaciones-page.component";
import { IniciarSesionPageComponent } from "./components/common/iniciar-sesion-page/iniciar-sesion-page.component";
import { RecuperarPasswordPageComponent } from "./components/common/recuperar-password-page/recuperar-password-page.component";
import { autenticacionGuard } from "./guard/autenticacion.guard";
import { CursosPageComponent } from "./components/pages/cursos-page/cursos-page.component";
import { InicioComponent } from "./components/pages/inicio/inicio.component";
import { DetallesCursoPageComponent } from "./components/pages/detalles-curso-page/detalles-curso-page.component";
import { AsistenciaPageComponent } from "./components/pages/asistencia-page/asistencia-page.component";
import { BibliotecaComponent } from "./components/pages/biblioteca-page/biblioteca.component";
import { BibliotecaDetallesComponent } from "./components/pages/biblioteca-detalles-page/biblioteca-detalles.component";
import { MaterialCursoComponent } from "./components/pages/material-curso-page/material-curso.component";
import { DiplomasPageComponent } from "./components/pages/diplomas-page/diplomas-page.component";
import { encuestaGuard } from "./guard/encuesta.guard";

export const routes: Routes = [
  { path: "iniciar-sesion", component: IniciarSesionPageComponent },
  { path: "recuperar-contrasena", component: RecuperarPasswordPageComponent },
  { path: "recuperar-contrasena/:email/:token", component: RecuperarPasswordPageComponent },
  {
    path: "",
    component: InicioComponent,
    data: { breadcrumb: "Inicio" },
    canActivate: [autenticacionGuard, encuestaGuard],
  },
  {
    path: "cursos",
    component: CursosPageComponent,
    data: { breadcrumb: "Cursos" },
    canActivate: [autenticacionGuard, encuestaGuard],
  },
  {
    path: "curso/material/:idCurso/:nombreCurso/:urlCurso/:idCalendario",
    component: MaterialCursoComponent,
    data: { breadcrumb: "Material :nombreCurso" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "curso/material/:idCurso/:nombreCurso/:urlCurso",
    component: MaterialCursoComponent,
    data: { breadcrumb: "Material :nombreCurso" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "curso/:idcalendario/:url",
    component: DetallesCursoPageComponent,
    data: { breadcrumb: "Detalles :url" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "asistencia/:idcalendario/:url",
    component: AsistenciaPageComponent,
    data: { breadcrumb: "Asistencias :url" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "biblioteca",
    component: BibliotecaComponent,
    data: { breadcrumb: "Biblioteca" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "biblioteca/detalles/:idRepositorio/:url",
    component: BibliotecaDetallesComponent,
    data: { breadcrumb: "Detalles :url" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "diplomas",
    component: DiplomasPageComponent,
    data: { breadcrumb: "Diplomas" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "perfil",
    component: EditAccountPageComponent,
    data: { breadcrumb: "Perfil" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "usuarios",
    component: UsuariosPageComponent,
    data: { breadcrumb: "Usuarios" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "evaluaciones",
    component: EvaluacionesPageComponent,
    data: { breadcrumb: "Evaluaciones" },
    canActivate: [autenticacionGuard]
  },

  { path: "**", component: CursosPageComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
