import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DiplomaService {
  private urlApi: string = `${environment.baseApi}diploma`;

  constructor(private http: HttpClient) {}

  diplomasAlumno(idAlumno: any) {
    const headers = this.crearHeaders();
    return this.http
      .get(`${this.urlApi}/alumno/${idAlumno}`, { headers })
      .toPromise();
  }

  diplomasCalendario(idCalendario:any){
    const headers = this.crearHeaders();
    return this.http
      .get(`${this.urlApi}/calendario/${idCalendario}`, { headers })
      .toPromise();
  }

  private crearHeaders(){
    return new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
  }
}
